import { memo } from "react";

const Auth = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Login/Logout/Change Password</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Login</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/login.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can login by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Input username</li>
                        <li>2. Input password</li>
                        <li>3. Click on [Login] button to login  into Admin web</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>2. Logout</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/logout.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can logout by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Account Icon] button in the top right corner.</li>
                        <li>2. Click on [Logout] button to logout.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>3. Change password</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/change-password.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can change their own password by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Account Icon] button in the top right corner.</li>
                        <li>2. Click on [Change Password] button.</li>
                    </ul>
                    <div className="mt-4">Upon that, the Change Passsword Pop Up appear.</div>
                    <div className="text-center">
                        <img
                            src={`/img/manual/change-password-popup.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5 mt-4">
                        <li>1. Input your current password.</li>
                        <li>2. Input your new desired password.</li>
                        <li>3. Input once again to confirm your new password.</li>
                        <li>4. Click on [Cancel] or [X] button to cancel the change.</li>
                        <li>5. Click on [Update] button to make the change. After change successfully, a message [Change Password Success] will appear.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(Auth);