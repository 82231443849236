import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OperatorStatus, DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, HttpCode, RouteUrl } from "../../models/constants";
import { GroupInfo, UserInfo, UserListRequest, UserListResponse } from "../../interface";
import adminApi from "../../api/admin.api";
import PaginationPage from "../pagination";

interface Props {
    group: GroupInfo | undefined;
    showLoading: (value: boolean) => void;
}

const OperatorList = (props: Props) => {
    const { t } = useTranslation();
    const { group, showLoading } = props;
    const navigate = useNavigate();

    const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [total, setTotal] = useState<number>(0);

    const [listUser, setListUser] = useState<UserInfo[]>([]);

    useEffect(() => {
        getUserList();
    }, [pageNo, pageSize, group]);

    const getUserList = () => {
        setListUser([]);
        let params: UserListRequest = {
            groupId: (group && group?.groupId !== 0) ? group?.groupId?.toString() : null,
            pageNo: pageNo,
            pageSize: pageSize,
        }
        showLoading(true);

        adminApi.getListUser(params).then(res => {
            showLoading(false);
            let data = res.data as UserListResponse;
            if (data.meta.code === HttpCode.OK) {
                setTotal(data.data.totalRecords);
                setListUser(data.data.userList.filter((user) => user?.status === OperatorStatus.ACTIVE));
            } else {
                setTotal(0);
                setListUser([]);
            }
        }, error => {
            setTotal(0);
            setListUser([]);
            showLoading(false);
        })
    }

    const changePageSize = (value: string) => {
        setPageSize(Number(value));
        setPageNo(DEFAULT_PAGE_NO);
    }

    const gotoLink = (url: string) => {
        navigate(url);
    }

    const renderTable = () => {
        return (
            <div className="table-responsive table-modal mt-4">
                <table className="blockmodule-table table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-center">
                                {t('username')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('email')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listUser.map((user, index) => {
                            return (
                                <tr className="text-center" key={user?.userId}>
                                    <td>{user?.userName}</td>
                                    <td>{user?.email}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="modal fade" id="operatorList" tabIndex={-1} aria-labelledby="operatorList" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('operatorList')}</h5>
                        <div className="modal-subtitle">{group?.groupName}</div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <div className="row justify-content-end me-3">
                                <button className="btn btn-success btn-w-auto" onClick={() => gotoLink(RouteUrl.OPERATOR_LIST)} data-bs-dismiss="modal">
                                    {t('manageOperator')}<i className="bi bi-people ps-2"/>
                                </button>
                            </div>
                            {renderTable()}
                            {total > 0 &&<div className="blockmodule-footer my-1">
                                <PaginationPage
                                    pageNo={pageNo}
                                    pageSize={pageSize}
                                    totalItem={total}
                                    updatePageSize={changePageSize}
                                    updatePageNo={setPageNo}
                                />
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(OperatorList);