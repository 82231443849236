export const LocalStorageKey = {
    LANGUAGE: 'language',
    TOKEN: 'token',
    LOGIN_ID: "loginID",
    PASSWORD: "info",
    GROUPID: "groupID",
    RIGHTS: "rights"
}

export const HttpCode = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    DUPLICATE: 409
}

export const UserStatus = {
    LOGGED_IN: "1",
    LOGGED_OUT: "2",
    FAILED: "3"
}

export const MaintenanceStatus = {
    APPLIED: "1",
    SCHEDULED: "0",
    CANCELED: "2"
}

export const MaintenanceType = {
    SETTING: "0",
    INSTANT: "1"
}

export const RouteUrl = {
    LOGIN: "/login",
    GENERAL_SETTING: "/setting/payout",
    SYMBOL_SETTING: "/setting/symbol",
    MAINTENANCE: "/setting/maintenance",
    USER_ONLINE: "/user/online",
    USER_HISTORY: "/user/history",
    OPERATOR_LIST: "/setting/operator",
    GROUP_SETTING: "/setting/group",
    AUDIT_HISTORY: "/setting/account/audit-history",
    MANUAL: "/manual",
}

export const ApiPath = {
    LOGIN: "/api/v1/gbhl/admin/auth",
    LOGOUT: "/api/v1/gbhl/admin/logout",
    GET_GENERAL_CONFIG: "/api/v1/gbhl/admin/config/general",
    GET_SYMBOL_CONFIG: "/api/v1/gbhl/admin/config/symbol",
    UPDATE_GENERAL_CONFIG: "/api/v1/gbhl/admin/config/general/modify",
    UPDATE_SYMBOL_CONFIG: "/api/v1/gbhl/admin/config/symbol/modify",
    GET_LOGIN_HISTORY: "/api/v1/gbhl/admin/login-history",
    GET_ONLINE_USER: "/api/v1/gbhl/admin/online-user",
    EXPORT_LOGIN_HISTORY: "/api/v1/gbhl/admin/login-history/csv",
    FORCE_LOGOUT: "/api/v1/gbhl/admin/force-logout",
    GET_MAINTENANCE_HISTORY: "/api/v1/gbhl/admin/maintenance/history",
    CREATE_MAINTENANCE: "/api/v1/gbhl/admin/maintenance/create",
    EDIT_MAINTENANCE: "/api/v1/gbhl/admin/maintenance/edit",
    EXECUTE_MAINTENANCE: "/api/v1/gbhl/admin/maintenance/execute",
    GET_USER_LIST: "/api/v1/gbhl/admin/user/list",
    GET_GROUP_LIST: "/api/v1/gbhl/admin/user/group",
    CREATE_USER: "/api/v1/gbhl/admin/user/create",
    USER_API: "/api/v1/gbhl/admin/user/",
    CHANGE_PASSWORD: "/api/v1/gbhl/admin/change-password",
    GET_RIGHT_LIST: "/api/v1/gbhl/admin/group/rights",
    GET_GROUP_FUNCTIONS: "/api/v1/gbhl/admin/group/functions",
    CREATE_GROUP: "/api/v1/gbhl/admin/group/create",
    GET_GROUP_RIGHTS: "/api/v1/gbhl/admin/group/rights",
    GROUP_API: "/api/v1/gbhl/admin/group/",
    GET_AUDIT_HISTORY: "/api/v1/gbhl/admin/user/audit-history",
    EXPORT_AUDIT_HISTORY: "/api/v1/gbhl/admin/user/audit-history/csv",
    PREPARE_MAINTENANCE: "/api/v1/gbhl/admin/maintenance/prepare"
}

export const DEFAULT_LANG = "en";
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 10000;
export const DEFAULT_PAGE_NO = 1;
export const LIST_PAGE_SIZE = [20, 50, 100];
export const ONE_DAY_TIME = 86400000;
export const MAX_LENGTH_GROUP_NAME = 50;

export const DateFormat = {
    Default: "DD/MM/YYYY",
    DatePicker: "yyyy/MM/dd",
    DateTimePicker: "yyyy/MM/dd HH:mm",
    DateTime: "yyyy/MM/DD HH:mm:ss",
    CsvTime: "YYYYMMDD"
}

export const OperatorStatus = {
    ACTIVE: 1,
    DEACTIVE: 0
}

export const RightValue = {
    NONE: 0,
    READ: 1,
    WRITE: 2,
    FULL: 3
}

export const FunctionName = {
    PAYOUT_SETTING: "Payout Configuration",
    SYMBOL_SETTING: "Symbol Configuration",
    LOGIN_HISTORY: "Login History",
    USER_ONLINE: "Online Users",
    MAINTENANCE: "Maintenance",
    OPERATOR_LIST: "Operator List",
    OPERATOR_GROUP: "Operator Group",
    AUDIT_HISTORY: "Audit History"
}

export const FunctionRights = {
    NONE: 0,
    READ: 1,
    WRITE: 3,
}

export const ExclusiveFunctions = [
    "Others",
]

export const ManualRouterUrl = {
    AUTH: "/manual/auth",
    PAYOUT_SYMBOL: "/manual/payout-symbol",
    LOGIN_HISTORY: "/manual/login-history",
    ONLINE_USER: "/manual/online-users",
    MAINTAINANCE: "/manual/maintainance",
    OPERATOR_LIST: "/manual/operator-list",
    GROUP_SETTINGS: "/manual/group-settings",
    AUDIT_HISTORY: "/manual/audit-history",
}
