import axios from 'axios';
import { HttpCode, LocalStorageKey, RouteUrl } from "../models/constants";
import { AxiosRequestConfig } from "axios";

const axiosProto: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'arraybuffer',
    timeout: 30000,
}

const client = axios.create(axiosProto);

const ClientService = {
    fetch(url: string, config?: any) {
        return fetch(process.env.REACT_APP_API_URL + url, { ...this.configHeader(), ...config });
    },

    get(url: string, config?: any) {
        return client.get(url, { ...this.configHeader(), ...config });
    },

    post(url: string, params: any, config?: any) {
        return client.post(url, params, { ...this.configHeader(), ...config });
    },

    put(url: string, params: any, config?: any) {
        return client.put(url, params, { ...this.configHeader(), ...config });
    },

    delete(url: string, config?: any) {
        return client.delete(url, { ...this.configHeader(), ...config });
    },

    configHeader() {
        return {
            headers: {
                'Content-Type': 'application/x-protobuf',
                'Authorization': `${localStorage.getItem(LocalStorageKey.TOKEN)}`
            }
        }
    }
};

client.interceptors.response.use(res => {
    return res;
}, error => {
    if (error?.response?.status === HttpCode.UNAUTHORIZED) {
        localStorage.clear();
        window.location.pathname = RouteUrl.LOGIN;
    } else {
        throw error;
    }
})

export default ClientService;
