import { memo, useEffect } from "react";
import { ManualRouterUrl } from "../../models/constants";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

const ListManual = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location?.pathname]);

    const hideListManual = () => {
        $('#toggleListManual').click();
    }

    return (
        <div className="row manual-list">
            <div className="text-center manual-list-title">Table of contents</div>
            <div className="col-sm-6 manual-list-item">
                <ul>
                    <Link to={ManualRouterUrl.AUTH} onClick={hideListManual}><li>Auth</li></Link>
                    <Link to={ManualRouterUrl.PAYOUT_SYMBOL} onClick={hideListManual}><li>Payout/Symbol</li></Link>
                    <Link to={ManualRouterUrl.LOGIN_HISTORY} onClick={hideListManual}><li>Login History</li></Link>
                    <Link to={ManualRouterUrl.ONLINE_USER} onClick={hideListManual}><li>Online Users</li></Link>
                </ul>
            </div>
            <div className="col-sm-6 manual-list-item">
                <ul>
                    <Link to={ManualRouterUrl.MAINTAINANCE} onClick={hideListManual}><li>Maintenance</li></Link>
                    <Link to={ManualRouterUrl.OPERATOR_LIST} onClick={hideListManual}><li>Operator List</li></Link>
                    <Link to={ManualRouterUrl.GROUP_SETTINGS} onClick={hideListManual}><li>Operator Group</li></Link>
                    <Link to={ManualRouterUrl.AUDIT_HISTORY} onClick={hideListManual}><li>Audit History</li></Link>
                </ul>
            </div>
        </div>
    );
}

export default memo(ListManual);