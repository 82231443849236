import { memo } from "react";

const OnlineUsers = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Online Users</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Online User List</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/online-user.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can monitor online users of BullBear Webtrade by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Online User] menu in the Side Menu.</li>
                        <li>2. Fill in Account ID if you want to search for a specific user.</li>
                        <li>3. Click on [Search] button to search for the user.</li>
                        <li>4. Click on [Refresh] button to get the latest online user.</li>
                        <li>5. Click on [Force Log Out] button to force the user out of the BullBear Webtrade.</li>
                    </ul>
                    <div className="text-center">
                        <img
                            src={`/img/manual/force-logout.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">Upon that, a confirmation pop up appear.</div>
                    <ul className="list-instruction ms-5">
                        <li>6. Click on [Yes] button to actually force the user out of the BullBear Webtrade.</li>
                        <li>7. Click [No] or [X] button to cancel forcing the user out of the BullBear Webtrade.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(OnlineUsers);