import { memo } from "react";
import { useTranslation } from "react-i18next";
import { GroupInfo, RightInfo } from "../../interface";
import { FunctionRights } from "../../models/constants";

interface Props {
    group: GroupInfo;
    right: RightInfo | undefined;
    handleOperatorList: (group: GroupInfo) => void;
    handleEditSetting: (group: GroupInfo) => void;
    handleDeleteGroup: (group: GroupInfo) => void;
}
const OperatorGroupRow = (props: Props) => {
    const { t } = useTranslation();
    const { group, right, handleOperatorList, handleEditSetting, handleDeleteGroup } = props;

    return (
        <tr className="text-center">
            <td>{group?.groupId}</td>
            <td>{group?.groupName}</td>
            <td>
                <button className="btn btn-w-auto btn-success" onClick={() => handleOperatorList(group)} data-bs-toggle="modal" data-bs-target="#operatorList">
                    {t('operatorList')}
                </button>
            </td>
            {
                right?.rights === FunctionRights.WRITE ?
                <>
                    <td>
                        <button className="btn btn-w-auto btn-danger" onClick={() => handleDeleteGroup(group)} data-bs-toggle="modal" data-bs-target="#confirmDeleteGroup">
                            {t('deleteGroup')}<i className="bi bi-trash3 ms-1" />
                        </button>
                    </td>
                    <td>
                        <button className="btn btn-w-auto btn-darkblue" onClick={() => handleEditSetting(group)} data-bs-toggle="modal" data-bs-target="#confirmEditSetting">
                            <i className="bi bi-pencil-square mx-0" />
                        </button>
                    </td>
                </> 
                : <>
                    <td></td>
                    <td></td>
                </>
            }
        </tr>
    )
}
export default memo(OperatorGroupRow);