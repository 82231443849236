import { ReactNode, memo, useEffect, useState } from "react"
import { ManualRouterUrl, RouteUrl } from "../../models/constants";
import { useLocation, useNavigate } from "react-router";
import ListManual from "./list-manual";
import { Outlet } from "react-router-dom";

interface Props {
    children?: ReactNode
}

const Layout = ({ children, ...props }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isClose, setIsClose] = useState(false);
    const [position, setPosition] = useState({
        backName: '',
        nextName: '',
        backPath: '',
        nextPath: ''
    });

    useEffect(() => {
        const listPathManual = Object.values(ManualRouterUrl);
        const currentPosition = listPathManual.findIndex((path) => path === location.pathname);
        if (currentPosition === -1) return;
        const backPositionPath = currentPosition === 0 ? listPathManual[listPathManual.length - 1] : listPathManual[currentPosition - 1];
        const nextPositionPath = currentPosition === listPathManual.length - 1 ? listPathManual[0] : listPathManual[currentPosition + 1];

        const backPositionName = changePathToName(backPositionPath);
        const nextPositionName = changePathToName(nextPositionPath);
        setPosition({
            backName: backPositionName,
            nextName: nextPositionName,
            backPath: backPositionPath,
            nextPath: nextPositionPath
        })
    }, [location]);

    const changePathToName = (path: string) => {
        const rawname = path.split('/manual')[1];
        return rawname === "" ? "/manual" : rawname;
    }

    const goToPage = (path: string) => {
        navigate(path);
    }

    return (
        <div className="container-manual">
            <div className="manual-subtab">
                <div className="row manual-title">
                    <div className={` btn-w-auto pe-0`}>
                        <a href={RouteUrl.GENERAL_SETTING} className="navbar-logo__text">
                            {process.env.REACT_APP_TITLE}
                        </a>
                    </div>
                    <div className="col p-1">
                        <div className="d-flex align-items-end justify-content-end h-100">
                            <span data-bs-toggle="tooltip" title="Back To Admin" onClick={() => goToPage(RouteUrl.GENERAL_SETTING)}>
                                <i className="bi bi-house-fill me-3 text-white cursor_pointer" style={{ fontSize: "20px" }}></i>
                            </span>
                            <div id="toggleListManual" data-bs-toggle="collapse" data-bs-target="#listManual" aria-expanded="false" aria-controls="listManual"
                                className="cursor-pointer manual-collapse"
                                onClick={() => setIsClose(!isClose)}
                            >
                                {!isClose && <i className="bi bi-chevron-double-down cursor_pointer" style={{ fontSize: "20px" }}></i>}
                                {isClose && <i className="bi bi-chevron-double-up cursor_pointer" style={{ fontSize: "20px" }}></i>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse" id="listManual">
                    <ListManual />
                </div>
                <div className="manual-content">
                    {/* Where your manual page routes should be rendered */}
                    <Outlet />
                </div>
                <div className="manual-footer">
                    <div className="row">
                        <div className="col-sm-6 pb-1">
                            <a onClick={() => goToPage(position.backPath)}>
                                <button className="manual-btn-step w-100 text-uppercase">{position.backName.split('/')[1]}</button>
                            </a>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <a onClick={() => goToPage(position.nextPath)}>
                                <button className="manual-btn-step w-100 text-uppercase">{position.nextName.split('/')[1]}</button>
                            </a>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}

export default memo(Layout);