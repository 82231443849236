import { memo } from "react"
import { Link, useNavigate } from "react-router-dom";
import { ManualRouterUrl, RouteUrl } from "../../models/constants";

const Manual = () => {
    const navigate = useNavigate();

    const goToPage = (path: string) => {
        navigate(path);
    }

    return (
        <div className="container-manual">
            <div className="manual-subtab">
                <div className="row manual-list">
                    <div className="position-relative">
                        <div className="text-center manual-list-title">Table of contents</div>
                        <div className="position-absolute bottom-50 end-0" data-bs-toggle="tooltip" title="Back To Admin" onClick={() => goToPage(RouteUrl.GENERAL_SETTING)}>
                            <i className="bi bi-house-fill me-3 text-white cursor_pointer" style={{ fontSize: "20px" }}></i>
                        </div>
                    </div>
                    <div className="col-sm-6 manual-list-item">
                        <ul>
                            <Link to={ManualRouterUrl.AUTH}><li>Auth</li></Link>
                            <Link to={ManualRouterUrl.PAYOUT_SYMBOL}><li>Payout/Symbol</li></Link>
                            <Link to={ManualRouterUrl.LOGIN_HISTORY}><li>Login History</li></Link>
                            <Link to={ManualRouterUrl.ONLINE_USER}><li>Online Users</li></Link>
                        </ul>
                    </div>
                    <div className="col-sm-6 manual-list-item">
                        <ul>
                            <Link to={ManualRouterUrl.MAINTAINANCE}><li>Maintenance</li></Link>
                            <Link to={ManualRouterUrl.OPERATOR_LIST}><li>Operator List</li></Link>
                            <Link to={ManualRouterUrl.GROUP_SETTINGS}><li>Group Settings</li></Link>
                            <Link to={ManualRouterUrl.AUDIT_HISTORY}><li>Audit History</li></Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Manual);