import { memo, useEffect, useState } from "react"
import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import Loading from "../components/loading";
import ConfirmCreateGroup from "../components/modal/confirm-create-group";
import ConfirmEditSetting from "../components/modal/confirm-edit-setting";
import OperatorList from "../components/modal/operator-list";
import ConfirmDeleteGroup from "../components/modal/confirm-delete-group";
import { RightInfo } from "../interface";
import { FunctionName, RightValue } from "../models/constants";
import { getRight } from "../utils";
import adminApi from "../api/admin.api";
import { GroupInfo, GroupResponse } from "../interface";
import { HttpCode } from "../models/constants";
import OperatorGroupRow from "../components/row/operator-group-row";

const GroupSetting = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    
    const [listGroup, setListGroup] = useState<GroupInfo[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupInfo>();
    const [groupEdit, setGroupEdit] = useState<GroupInfo>();
    const [groupDelete, setGroupDelete] = useState<GroupInfo>();
    const [clickIndex, setClickIndex] = useState(0);
    const [right, setRight] = useState<RightInfo>();

    useEffect(() => {
        let mRight = getRight(FunctionName.OPERATOR_GROUP);
        if (mRight) setRight(mRight);
        getGroup();
    }, []);

    const getGroup = () => {
        adminApi.getListActiveGroup().then(res => {
            let data = res.data as GroupResponse;
            if (data.meta.code === HttpCode.OK) {
                setListGroup(data.data.groupList.sort((a,b) => a.groupId - b.groupId));
            } else setListGroup([]);
        })
    }

    const handleOperatorList = (group: GroupInfo) => {
        setSelectedGroup(group);
    }

    const handleEditSetting = (group: GroupInfo) => {
        setClickIndex(clickIndex + 1);
        setGroupEdit(group);
    }

    const handleDeleteGroup = (group: GroupInfo) => {
        setGroupDelete(group);
    }

    const showLoading = (show: boolean) => {
        setIsLoading(show);
    }

    const onReload = () => {
        getGroup();
    }

    const renderHeader = () => {
        return (
            <form>
                <div className="row mt-2">
                    <label htmlFor="" className="col-sm-8 col-form-label fw-bold w-fit_content">{t('group')}</label>
                    <div className="col-sm-4 text-end">
                        {right?.rights === RightValue.FULL && <button type="button" className="btn btn-w-auto btn-success" data-bs-toggle="modal" data-bs-target="#confirmCreateGroup">
                            {t('createGroup')}<i className="bi bi-boxes ps-2"/>
                        </button>}
                    </div>
                </div>
            </form>
        )
    }

    const renderTable = () => {
        return (
            <div className="table-responsive mt-2">
                <table className="blockmodule-table table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-center">
                                {t('id')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('groupName')}
                            </th>
                            <th scope="col" className="align-middle text-center"></th>
                            <th scope="col" className="align-middle text-center"></th>
                            <th scope="col" className="align-middle text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listGroup?.map((group, index) => {
                            return (
                                <OperatorGroupRow key={group?.groupId} group={group}
                                    right={right}
                                    handleOperatorList={handleOperatorList}
                                    handleEditSetting={handleEditSetting}
                                    handleDeleteGroup={handleDeleteGroup}/>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Layout>
            <section id="operatorGroup">
                <div className="blockmodule blockstock blockmoule-fullpage">
                    <div className="blockmodule-wrap">
                        <div className="blockmodule-wrap--shadown">
                            <div className="blockmodule-title">
                                <h2>{t('operatorGroup')}</h2>
                            </div>
                            <div className="blockmodule-mainbody">
                                {renderHeader()}
                                {renderTable()}
                            </div>
                        </div>
                    </div>
                    <Loading loading={isLoading} />
                </div>
                <ConfirmCreateGroup showLoading={showLoading} onReload={onReload}/>
                <OperatorList group={selectedGroup} showLoading={showLoading}/>
                <ConfirmEditSetting clickIndex={clickIndex} group={groupEdit} showLoading={showLoading} onReload={onReload}/>
                <ConfirmDeleteGroup group={groupDelete} showLoading={showLoading} onReload={onReload}/>
            </section>
        </Layout>
    )
}
export default memo(GroupSetting);