import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FunctionName, LocalStorageKey, RightValue, RouteUrl} from "../models/constants";
import {useLocation, useNavigate} from "react-router-dom";
import useDetectMobile from "../hooks/useDetectMobile";
import { RightInfo } from "../interface";
import { isEmpty } from "../utils";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isMobile = useDetectMobile();

    const [rightList, setRightList] = useState<RightInfo[]>([]);

    useEffect(() => {
        let rightCache = localStorage.getItem(LocalStorageKey.RIGHTS) ?? "";
        if (!isEmpty(rightCache)) {
            setRightList(JSON.parse(rightCache));
        } else {
            localStorage.clear();
            gotoLink(RouteUrl.LOGIN);
        }
    }, [])

    const getClazz = (url: string) => {
        return location.pathname.includes(url) ? 'active' : '';
    }

    const gotoLink = (url: string) => {
        navigate(url);
    }

    const checkReadRight = (funcName: string) => {
        let check = false;
        let rightArr = rightList.filter(r => r.functionName === funcName);
        if (rightArr) {
            let right =  rightArr[0];
            if (right) {
                if (right.rights === RightValue.READ || right.rights === RightValue.FULL) {
                    check = true;
                } else check = false;
            }
            
        }
        return check;
    }

    return (
        <nav className={`main-navbar ${process.env.REACT_APP_BACKGROUND}`}>
            <div className="inner-navbar">
                <ul className="main-navbar__menu">
                    {checkReadRight(FunctionName.PAYOUT_SETTING) && <li className={`${getClazz(RouteUrl.GENERAL_SETTING)}`}>
                        <a onClick={() => gotoLink(RouteUrl.GENERAL_SETTING)} data-content-id="generalSetting">
                            <i className="bi bi-grid-1x2"/>
                            {!isMobile && <span>{t('generalSetting')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.SYMBOL_SETTING) && <li className={getClazz(RouteUrl.SYMBOL_SETTING)}>
                        <a onClick={() => gotoLink(RouteUrl.SYMBOL_SETTING)} data-content-id="symbolSetting">
                            <i className="bi bi-collection"/>
                            {!isMobile && <span>{t('symbolSetting')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.LOGIN_HISTORY) && <li className={getClazz(RouteUrl.USER_HISTORY)}>
                        <a onClick={() => gotoLink(RouteUrl.USER_HISTORY)} data-content-id="userHistory">
                            <i className="bi bi-card-list"/>
                            {!isMobile && <span>{t('loginHistory')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.USER_ONLINE) && <li className={getClazz(RouteUrl.USER_ONLINE)}>
                        <a onClick={() => gotoLink(RouteUrl.USER_ONLINE)} data-content-id="userOnline">
                            <i className="bi bi-person-check"/>
                            {!isMobile && <span>{t('userOnline')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.MAINTENANCE) && <li className={getClazz(RouteUrl.MAINTENANCE)}>
                        <a onClick={() => gotoLink(RouteUrl.MAINTENANCE)} data-content-id="maintenance">
                            <i className="bi bi-gear"/>
                            {!isMobile && <span>{t('maintenance')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.OPERATOR_LIST) && <li className={getClazz(RouteUrl.OPERATOR_LIST)}>
                        <a onClick={() => gotoLink(RouteUrl.OPERATOR_LIST)} data-content-id="operatorList">
                            <i className="bi bi-people"/>
                            {!isMobile && <span>{t('operatorList')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.OPERATOR_GROUP) && <li className={getClazz(RouteUrl.GROUP_SETTING)}>
                        <a onClick={() => gotoLink(RouteUrl.GROUP_SETTING)} data-content-id="groupSetting">
                            <i className="bi bi-boxes"/>
                            {!isMobile && <span>{t('operatorGroup')}</span>}
                        </a>
                    </li>}
                    {checkReadRight(FunctionName.AUDIT_HISTORY) && <li className={getClazz(RouteUrl.AUDIT_HISTORY)}>
                        <a onClick={() => gotoLink(RouteUrl.AUDIT_HISTORY)} data-content-id="auditHistory">
                            <i className="bi bi-card-checklist"/>
                            {!isMobile && <span>{t('auditHistory')}</span>}
                        </a>
                    </li>}
                    <li className={getClazz(RouteUrl.MANUAL)}>
                        <a onClick={() => gotoLink(RouteUrl.MANUAL)} data-content-id="auditHistory">
                            <i className="bi bi-journal-bookmark"/>
                            {!isMobile && <span>{t('manual')}</span>}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default memo(Sidebar);