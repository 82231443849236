import { memo } from "react";

const GroupSettings = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Operator Group</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Operator Group</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/group-setting-main-screen.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can see group list and settings by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Operator Group] Menu in the Side Menu.</li>
                    </ul>
                    <div className="mt-4">There is list of Groups that existed with the assigned role and permission. User can also see list of operators that belong to to each group. </div>
                </div>

                <div className="mt-4">
                    <strong>2. Operator List</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/member-list1.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5 mt-4">
                        <li>1. Click on [Operator List] button at each Group to see list of operators who belong to each group. Upon that, a pop up displaying a list of operators will be shown on the screen.</li>
                    </ul>
                    <div className="text-center">
                        <img
                            src={`/img/manual/member-list2.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5 mt-4">
                        <li>2. Click on [Manage Operator] button to redirect to Operator Management screen.</li>
                        <li>3. Click on [X] button to close the pop up.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>3. Edit Group</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/edit-settings1.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can edit right and permission of each group by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Edit] button on each group. Upon that, a pop up window will be shown on the screen.</li>
                    </ul>
                    <div className="text-center">
                        <img
                            src={`/img/manual/edit-settings2.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5 mt-4">
                        <li>2. Input new name to edit name of the group.</li>
                        <li>3. Check/Uncheck on each checkbox to change right and permission of the Group for each function. (3 and 4).</li>
                        <ul>
                            <li>+ If the Group has permission for read only, the users belonging to the group can only see information on the screen. They will not be able to create/edit/delete any information.</li>
                            <li>+ If the Group has permission for write, the users can see the information and also can create/edit/delete any information.</li>
                        </ul>
                        <li>5. Click [Submit] button to apply all the changes.</li>
                        <li>6. Click [Cancel] or [X] button to cancel all the changes.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>4. Create Group</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/create-group1.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can also create new group by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Create Group] button in the top right corner.</li>
                    </ul>
                    <p>Upon that, a pop up window will be shown on the screen.</p>
                    <div className="text-center">
                        <img
                            src={`/img/manual/create-group2.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5 mt-4">
                        <li>2. Input name for the new group.</li>
                        <li>3. Check/Uncheck on each checkbox to grant the right and permission of the Group for each function. (3 and 4).</li>
                        <ul>
                            <li>+ If the Group has permission for read only, the users belonging to the group can only see information on the screen. They will not be able to create/edit/delete any information.</li>
                            <li>+ If the Group has permission for write, the users can see the information and also can create/edit/delete any information.</li>
                        </ul>
                        <li>4. Click the [Submit] button to create a new group.</li>
                        <li>5. Click [Cancel] or [X] button to cancel creating the new group.</li>
                    </ul>
                    <p>The newly created group will be shown on the Group List screen, and every Group drop list in Operator Management function.</p>
                </div>

                <div className="mt-4">
                    <strong>5. Delete Group</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/delete-group1.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can delete group existed in Admin Page by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Delete Group] button on each Group (Note: A group can only be deleted if there is no existing operator belonging to this group).</li>
                    </ul>
                    <p>Upon that a confirmation pop up will be shown on the screen.</p>
                    <div className="text-center">
                        <img
                            src={`/img/manual/delete-group2.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5">
                        <li>2. Click on [Yes] button to delete the group.</li>
                        <li>3. Click on [No] or [X] button to cancel deleting the group.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(GroupSettings);