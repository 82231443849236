import { memo } from "react";

const AuditHistory = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Audit History</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <div className="text-center">
                        <img
                            src={`/img/manual/audit-history.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can track all the audit log in the admin system by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Audit History] menu in the Side Menu.</li>
                        <li>2. Fill/Choose all the searching conditions in [2, 3, 4, 5].</li>
                        <li>6. Click on [Apply] to get the result according to searching conditions.</li>
                        <li>7. Click on [Export] button to get the result in CSV file.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(AuditHistory);