import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import adminApi from "../../api/admin.api";
import * as Notify from "../../components/notify";
import { BaseResponse, CreateUserRequest, CreateUserResponse, GroupInfo } from "../../interface";
import { isEmail, isEmpty } from "../../utils";
import $ from 'jquery';
import { HttpCode } from "../../models/constants";
interface Props {
    listGroup: GroupInfo[],
    clickIndex: number,
    showLoading: (show: boolean) => void,
    onReload: () => void
}
const ConfirmAddOperator = (props: Props) => {
    const { t } = useTranslation();
    const { listGroup, clickIndex, showLoading, onReload } = props;

    const [fullName, setFullName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState("")
    const [groupId, setGroupId] = useState("1");

    const [check, setCheck] = useState(false);
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        setUserName("");
        setFullName("");
        setEmail("");
        setGroupId("1");
        setShowResult(false);
    }, [clickIndex])

    useEffect(() => {
        setCheck(!isEmpty(userName) && !isEmpty(fullName) && !isEmpty(email) && isEmail(email));
    }, [userName, fullName, email])

    const createUser = () => {
        let params : CreateUserRequest = {
            userName: userName,
            fullName: fullName,
            email: email,
            status: true,
            group: groupId
        }

        showLoading(true);

        adminApi.createUser(params).then(res => {
            let data = res.data as CreateUserResponse;
            showLoading(false);
            
            if (data.meta.code === HttpCode.OK) {
                onReload();
                Notify.success(t('createUserSuccess'));
                setShowResult(true);
                setPassword(data.data.password);
            } else if (data.meta.code === HttpCode.DUPLICATE) {
                Notify.error(data.meta.message);
            } else {
                $(".btn-close").click();
                Notify.error(t('createUserFail'));
            } 
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('createUserFail'));
        })
    }

    return (
        <div className="modal fade" id="confirmAddOperator" tabIndex={-1} aria-labelledby="confirmAddOperator" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('createOperator')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {!showResult && <form className="m-3">
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('username')} <span className="text-danger">*</span></label>
                                <input type="input" className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)}/>
                                {!userName && <label className="text-danger">{t('fieldRequired')}</label>}
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('fullName')} <span className="text-danger">*</span></label>
                                <input type="input" className="form-control" value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                                {!fullName && <label className="text-danger">{t('fieldRequired')}</label>}
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('email')} <span className="text-danger">*</span></label>
                                <input type="input" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                {!email && <label className="text-danger">{t('fieldRequired')}</label>}
                                {email && !isEmail(email) && <label className="text-danger">{t('emailFormatInvalid')}</label>}
                            </div>
                            <div className="form-group mb-5">
                                <label className="col-sm-4 col-form-label">{t('group')} <span className="text-danger">*</span></label>
                                <select className="form-select" value={groupId} onChange={(e) => setGroupId(e.target.value)}>
                                    {listGroup && listGroup.map((group, idx) => {
                                        return(
                                            <option key={idx} value={group.groupId}>{group.groupName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </form>}
                        {showResult && <div className="m-3">
                            <div className="row mb-0">
                                <label className="col-sm-4 col-form-label">{t('username')}</label>
                                <label className="col-sm-8 col-form-label"><b>{userName}</b></label>
                            </div>
                            <div className="row mb-0">
                                <label className="col-sm-4 col-form-label">{t('fullName')}</label>
                                <label className="col-sm-8 col-form-label"><b>{fullName}</b></label>
                            </div>
                            <div className="row mb-0">
                                <label className="col-sm-4 col-form-label">{t('email')}</label>
                                <label className="col-sm-8 col-form-label"><b>{email}</b></label>
                            </div>
                            <div className="row mb-0">
                                <label className="col-sm-4 col-form-label">{t('group')}</label>
                                <label className="col-sm-8 col-form-label"><b>{listGroup.find(g => g.groupId === +groupId)?.groupName}</b></label>
                            </div>
                            <div className="row mb-0">
                                <label className="col-sm-4 col-form-label">{t('password')}</label>
                                <label className="col-sm-8 col-form-label"><b>{password}</b></label>
                            </div>
                        </div>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-bs-dismiss="modal">{t('cancel')}</button>
                        {!showResult && <button type="button" className="btn btn-darkblue mx-2" disabled={!check} onClick={createUser}>{t('submit')}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmAddOperator);