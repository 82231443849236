import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    col: number
}
const NoRecord = (props: Props) => {
    const { col } = props;
    const { t } = useTranslation();

    return (
        <tr>
            <td colSpan={col}>{t('noRecord')}</td>
        </tr>
    )
}
export default memo(NoRecord);