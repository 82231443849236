import { memo, useEffect, useRef, useState } from "react";
import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, DateFormat, FunctionName, HttpCode, RightValue, RouteUrl } from "../models/constants";
import Loading from "../components/loading";
import PaginationPage from "../components/pagination";
import moment from "moment";
import { GroupFunction, GroupFunctionResponse, RightInfo } from "../interface";
import { getRight } from "../utils";
import { AuditHistoryInfo, AuditHistoryRequest, AuditHistoryResponse } from "../interface";
import adminApi from "../api/admin.api";
import NoRecord from "../components/no-record";
import AuditHistoryRow from "../components/row/audit-history-row";
import fileDownload from "js-file-download";
import * as Notify from "../components/notify";
import { useNavigate } from "react-router-dom";

const AuditHistory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState<Date>(moment().startOf('day').toDate());
    const [toDate, setToDate] = useState<Date>(moment().endOf('day').toDate());
    const [showError, setShowError] = useState(false);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
    const [total, setTotal] = useState<number>(0);
    const [searchIndex, setSearchIndex] = useState(0);
    const [applyFlg, setApplyFlg] = useState(false);
    const [listAudit, setListAudit] = useState<AuditHistoryInfo[]>([]);
    const [username, setUsername] = useState("");

    const [right, setRight] = useState<RightInfo>();
    const [filterFunction, setFilterFunction] = useState(0);
    const [groupFunctions, setGroupFunctions] = useState<GroupFunction[]>();
    const sevenDaysBeforeDate = useRef(new Date(new Date().setDate(new Date().getDate() - 7)));
    const sevenDaysAfterDate = useRef(new Date(new Date().setDate(new Date().getDate() + 7)));

    useEffect(() => {
        let mRight = getRight(FunctionName.AUDIT_HISTORY);
        if (mRight) {
            if (mRight.rights === RightValue.READ || mRight.rights === RightValue.FULL)
                setRight(mRight);
            else {
                localStorage.clear();
                navigate(RouteUrl.LOGIN);
            } 
        }
        
        getGroupFunctions();
    }, [])

    useEffect(() => {
        if (fromDate && toDate) {
            if (fromDate.getTime() < toDate.getTime()){
                setShowError(false);
                setApplyFlg(true);
            } else {
                setShowError(true);
                setApplyFlg(false);
            }
        } else {
            setFromDate(moment().startOf('day').toDate());
            setToDate(moment().endOf('day').toDate());
        } 
    }, [fromDate, toDate])

    useEffect(() => {
        getAuditHistory();
    }, [pageSize, pageNo, searchIndex])


    const getAuditHistory = () => {
        let params: AuditHistoryRequest = {
            fromTime: fromDate ? fromDate.getTime() : null,
            toTime: toDate ? toDate.getTime() : null,
            username: username ? username : null,
            functionId: filterFunction !== 0 ? filterFunction : null,
            pageSize: pageSize,
            pageNo: pageNo
        }

        setIsLoading(true);

        adminApi.getAuditHistory(params).then((res: any) => {
            setIsLoading(false);
            let data = res?.data as AuditHistoryResponse;
            if (data.meta?.code === HttpCode.OK) {
                setTotal(data.data?.totalRecords ?? 0);
                setListAudit(data.data?.data ?? []);
            } else {
                setTotal(0);
                setListAudit([]);
            }
        }, error => {
            setIsLoading(false);
            setTotal(0);
            setListAudit([]);
        })
    }

    const getGroupFunctions = () => {
        adminApi.getGroupFunctions().then(res => {
            let data = res?.data as GroupFunctionResponse;
            if (data.meta.code === HttpCode.OK) {
                setGroupFunctions([{functionId: 0, functionName: "All"}, ...data?.data]);
            }
        })
    }

    const changePageSize = (value: string) => {
        setPageSize(Number(value));
        setPageNo(DEFAULT_PAGE_NO);
    }

    const onSearch = () => {
        setPageNo(DEFAULT_PAGE_NO);
        setPageSize(DEFAULT_PAGE_SIZE);
        setSearchIndex(searchIndex + 1);
    }

    const onExport = () => {
        let params = {
            fromTime: fromDate ? fromDate.getTime() : null,
            toTime: toDate ? toDate.getTime() : null,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            username: username ? username : null,
            functionId: filterFunction !== 0 ? filterFunction : null
        }

        setIsLoading(true);

        adminApi.exportAuditHistory(params).then((res: any) => {
            setIsLoading(false);
            let date = moment(new Date()).format(DateFormat.CsvTime);
            let fileName = 'AuditHistory_' + date + '.csv';
            fileDownload(res.data, fileName)

        }, error => {
            setIsLoading(false);
            Notify.error(t('exportCsvFail'));
        })
    }

    const handleKeyPress = (event : any) => {
        if(event.key === 'Enter') onSearch();
    }

    const renderSearch = () => {
        return (
            <div className="blockmodule-filter" onKeyDown={handleKeyPress}>
                <div className="row mt-2">
                    <div className="col-sm-3 pe-0">
                        <label className="form-label fw-bold mb-0">{t('fromDate')}</label>
                        <div className="input-group">
                            <DatePicker
                                dateFormat={DateFormat.DateTimePicker}
                                timeFormat="HH:mm"
                                className="form-control form-control-sm datepicker-m"
                                placeholderText={DateFormat.DateTimePicker.toLowerCase()}
                                showTimeInput
                                selected={fromDate}
                                minDate={sevenDaysBeforeDate?.current}
                                maxDate={sevenDaysAfterDate?.current}
                                onChange={(date: Date) => setFromDate(date)} />
                            {showError && <label className="text-danger">{t('invalidStartTime')}</label>}
                        </div>
                    </div>
                    <div className="col-sm-3 pe-0">
                        <label className="form-label fw-bold mb-0">{t('toDate')}</label>
                        <div className="input-group">
                            <DatePicker
                                dateFormat={DateFormat.DateTimePicker}
                                timeFormat="HH:mm"
                                className="form-control form-control-sm datepicker-m"
                                placeholderText={DateFormat.DateTimePicker.toLowerCase()}
                                showTimeInput
                                selected={toDate}
                                minDate={sevenDaysBeforeDate?.current}
                                maxDate={sevenDaysAfterDate?.current}
                                onChange={(date: Date) => setToDate(date)} />
                        </div>
                    </div>
                    <div className="text-danger mt-2">{t('timeFilterRestrictionMsg1')}</div>
                    <div className="text-danger">{t('timeFilterRestrictionMsg2')}</div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-3 pe-0">
                        <label className="form-label fw-bold mb-0">{t('username')}</label>
                        <input type="text" className="form-control" placeholder={t('all')!} onChange={(e) => setUsername(e?.target?.value)}/>
                    </div>
                    <div className="col-sm-2 pe-0">
                        <label className="form-label fw-bold mb-0">{t('function')}</label>
                        <select className="form-select" value={filterFunction} onChange={(e) => setFilterFunction(+e.target.value)}>
                            {groupFunctions && groupFunctions.map((groupFunction, idx) => {
                                return(
                                    <option key={idx} value={groupFunction?.functionId}>{groupFunction?.functionName}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="col-sm-2 pe-0">
                        <button className="btn btn-darkblue btn-x" onClick={onSearch} disabled={!applyFlg}>{t('applyBtn')}</button>
                    </div>
                    <div className="col-sm-5 text-end">
                        <button className="btn btn-info btn-x ms-2" onClick={onExport}><i
                            className="bi bi-filetype-csv" /> {t('exportBtn')}</button>
                    </div>
                </div>
            </div>
        )
    }

    const renderTable = () => {
        return (
            <div className="blockmodule-mainbody table-responsive mt-4">
                <table className="blockmodule-table table table-striped table-hover align-middle col-equal mb-2">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-start">
                                {t('time')}
                            </th>
                            <th scope="col" className="align-middle text-start">
                                {t('function')}
                            </th>
                            <th scope="col" className="align-middle text-start">
                                {t('userName')}
                            </th>
                            <th colSpan={2} scope="col" className="align-middle text-start">
                                {t('detail')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listAudit?.map((auditHistory, index) => {
                            return (
                                <AuditHistoryRow key={index}
                                    timeRequest={auditHistory?.timeRequest} 
                                    functionName={auditHistory?.function}
                                    username={auditHistory?.username}
                                    detail={auditHistory?.detail}/>
                            )
                        })}
                        {total === 0 && <NoRecord col={5} />}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Layout>
            <section id="auditHistory">
                <div className="blockmodule blockstock blockmoule-fullpage">
                    <div className="blockmodule-wrap">
                        <div className="blockmodule-wrap--shadown">
                            <div className="blockmodule-title">
                                <h2>{t('auditHistory')}</h2>
                            </div>
                            {renderSearch()}
                            {renderTable()}
                            <div className="blockmodule-footer my-1">
                                <PaginationPage
                                    pageNo={pageNo}
                                    pageSize={pageSize}
                                    totalItem={total}
                                    updatePageSize={changePageSize}
                                    updatePageNo={setPageNo}
                                />
                            </div>
                        </div>
                    </div>
                    <Loading loading={isLoading} />
                </div>
            </section>
        </Layout>
    )
}
export default memo(AuditHistory);