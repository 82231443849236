import { memo } from "react";

const LoginHistory = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Login History</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Login History</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/login-history.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can see all login history including Login, Logout, Fail Reason for Login History of BullBear Webtrade users in 1 week by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Login History] Menu in the Side Menu.</li>
                        <li>2. Fill in all the Search conditions in [2, 3, 4, 5].</li>
                        <li>6. Click [Search] button to get the result in the screen.</li>
                        <li>7. Click [Export] button to get the result in CSV file.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(LoginHistory);