import { memo, useEffect, useState } from "react";
import { UserInfo, RightInfo } from "../../interface";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils";
import { OperatorStatus, RightValue } from "../../models/constants";

interface Props {
    user: UserInfo,
    right: RightInfo  | undefined,
    handleDisableOperator: (user: UserInfo) => void,
    handleEnableOperator: (user: UserInfo) => void,
    handleResetPassword: (user: UserInfo) => void,
    handleEditOperator: (user: UserInfo) => void
}
const UserAdminRow = (props: Props) => {
    const { t } = useTranslation();
    const { user, right, handleDisableOperator, handleEnableOperator, handleResetPassword, handleEditOperator } = props;

    return (
        <tr className="text-center">
            <td>{user.userName}</td>
            <td>{user.fullName}</td>
            <td>{user.email}</td>
            <td>{user.groupName}</td>
            <td>{formatDate(user.registerTime)}</td>
            <td>
                {user.status === OperatorStatus.ACTIVE && right?.rights === RightValue.FULL &&<button type="button" className={`btn btn-success btn-checked-success`} onClick={() =>handleDisableOperator(user)} data-bs-toggle="modal" data-bs-target="#confirmDisableOperator">{t('enable')}</button>}
                {user.status === OperatorStatus.DEACTIVE && right?.rights === RightValue.FULL && <button className={`btn btn-danger btn-checked-danger`} onClick={() =>handleEnableOperator(user)} data-bs-toggle="modal" data-bs-target="#confirmEnableOperator">{t('disable')}</button>}
            </td>
            <td>
                {right?.rights === RightValue.FULL &&<button type="button" className="btn btn-w-auto btn-primary" onClick={() => handleResetPassword(user)} data-bs-toggle="modal" data-bs-target="#confirmResetPassword">{t('resetPassword')}</button>}
            </td>
            <td>
                {right?.rights === RightValue.FULL &&<button className="btn btn-w-auto btn-darkblue" onClick={() => handleEditOperator(user)} data-bs-toggle="modal" data-bs-target="#confirmEditOperator">
                    <i className="bi bi-pencil-square mx-0" />
                </button>}
            </td>
        </tr>
    )
}
export default memo(UserAdminRow);