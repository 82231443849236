import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import adminApi from "../../api/admin.api";
import { GroupFunction, GroupFunctionResponse, GroupInfo, Permission } from "../../interface";
import { ExclusiveFunctions, FunctionRights, HttpCode, MAX_LENGTH_GROUP_NAME } from "../../models/constants";
import * as Notify from "../../components/notify";
import $ from 'jquery';

interface Props {
    clickIndex: number;
    group: GroupInfo | undefined;
    showLoading: (value: boolean) => void;
    onReload: () => void;
}

const ConfirmEditSetting = (props: Props) => {
    const { t } = useTranslation();
    const { clickIndex, group, showLoading, onReload } = props;
    const [groupFunctionsUpdate, setGroupFunctionsUpdate] = useState<GroupFunction[]>();
    const [groupFunctions, setGroupFunctions] = useState<GroupFunction[]>();
    const [groupName, setGroupName] = useState(group?.groupName);
    const [canEdit, setCanEdit] = useState(false);
    const [errorMsg, setErrorMsg] = useState(t(''));

    useEffect(() => {
        if (group?.groupId)
            adminApi.getGroupRights(group?.groupId).then(res => {
                let data = res?.data as GroupFunctionResponse;
                if (data.meta.code === HttpCode.OK) {
                    //This constructor is to make sure {groupFunctions} and {groupFunctionsUpdate} don't point to the same reference
                    let dataFilteredFunctions = data?.data?.filter((groupFunction) => !ExclusiveFunctions.includes(groupFunction?.functionName));
                    let dataFunctions = JSON.parse(JSON.stringify(dataFilteredFunctions));
                    let dataFunctionsUpdate = JSON.parse(JSON.stringify(dataFilteredFunctions));
                    setGroupFunctionsUpdate(dataFunctionsUpdate);
                    setGroupFunctions(dataFunctions);
                }
            })
        setGroupName(group?.groupName);
        setErrorMsg('');
    }, [group, clickIndex]);

    useEffect(() => {
        let isGroupRightsChange = groupFunctionsUpdate?.some((groupFunction, index) => groupFunction?.rights !== groupFunctions![index]?.rights);
        let hasGroupRights = groupFunctionsUpdate?.some((groupFunction) => groupFunction?.rights ? groupFunction?.rights !== FunctionRights.NONE : false);
        
        if (errorMsg || !hasGroupRights || (!isGroupRightsChange && group?.groupName === groupName)) {
            setCanEdit(false);
        } else {
            setCanEdit(true);
        }
    }, [groupFunctionsUpdate, groupName]);

    const handleEditGroup = () => {
        let permissionList: Permission[] = groupFunctionsUpdate?.map((groupFunction) => {
            return {functionId: groupFunction?.functionId, rights: groupFunction?.rights || FunctionRights.NONE} as Permission;
        }) || [];
        
        let params = {
            group: {
                name: groupName ?? '',
                status: group?.groupStatus ?? '',
            },
            permission: permissionList,
        }

        showLoading(true);

        adminApi.editGroup(group!.groupId, params).then(res => {
            showLoading(false);
            $(".btn-close").click();
            onReload();
            Notify.success(t('editGroupSuccess'));
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('editGroupFail'));
        })
    }

    const handleUpdateFunctions = (type: number, functionId: number, rights: number | undefined) => {
        let newGroupFunctionUpdate = [...groupFunctionsUpdate || []];
        let selectedFunction = newGroupFunctionUpdate!.find((groupFunction) => groupFunction?.functionId === functionId);

        if ((rights && (rights >= type) && (rights & type))) {
            if (type === FunctionRights.READ) {
                Object.assign(selectedFunction!, {...selectedFunction, rights: FunctionRights.NONE});
            } else if (rights === FunctionRights.WRITE) {
                Object.assign(selectedFunction!, {...selectedFunction, rights: FunctionRights.READ});
            } else {
                Object.assign(selectedFunction!, {...selectedFunction, rights: rights ^ type});
            }
            setGroupFunctionsUpdate(newGroupFunctionUpdate);
        } else {
            Object.assign(selectedFunction!, {...selectedFunction, rights: (rights ? rights : FunctionRights.NONE) | +type});
            setGroupFunctionsUpdate(newGroupFunctionUpdate);
        }
    }

    const handleChangeGroupName = (e: any) => {
        let value = e?.target?.value;

        setCanEdit(false);

        if (!value) {
            setErrorMsg(t('groupNameBlankError')!);
        } else if (value?.length >= MAX_LENGTH_GROUP_NAME) {
            setErrorMsg(t('groupNameLengthError')!);
        } else {
            setErrorMsg('');
            setCanEdit(true);
        }
        setGroupName(value);
    }

    const getCheckState = (type: number, rights: number | undefined) => {
        if (rights && (rights >= type) && (rights & type)) return true;
        return false;
    }

    const renderTable = () => {
        return (
            <div className="table-responsive table-modal mt-4">
                <table className="blockmodule-table table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-center">
                                {t('functions')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('read')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('write')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groupFunctionsUpdate?.map((groupFunction) => {
                                return (
                                    <tr key={groupFunction?.functionId} className="text-center">
                                        <td>{groupFunction?.functionName}</td>
                                        <td>
                                            <input type="checkbox" checked={getCheckState(FunctionRights.READ, groupFunction?.rights)}
                                                onChange={() => handleUpdateFunctions(FunctionRights.READ, groupFunction?.functionId, groupFunction?.rights)} />
                                        </td>
                                        <td>
                                            <input type="checkbox" checked={getCheckState(FunctionRights.WRITE, groupFunction?.rights)}
                                                onChange={() => handleUpdateFunctions(FunctionRights.WRITE, groupFunction?.functionId, groupFunction?.rights)} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="modal fade" id="confirmEditSetting" tabIndex={-1} aria-labelledby="confirmEditSetting" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('editSettings')}</h5>
                        <div className="modal-subtitle">{group?.groupName}</div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <div className="row">
                                <label className="col-form-label col-sm-4">{t('rename')}</label>
                                <div className="col-sm-8">
                                    <input type="input" className="form-control" value={groupName || ""} onChange={(e) => handleChangeGroupName(e)}/>
                                    {
                                        errorMsg && 
                                            <div className="col-sm-8 text-danger">{errorMsg}</div>
                                    }
                                </div>
                            </div>
                            {renderTable()}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-bs-dismiss="modal">{t('cancel')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" onClick={handleEditGroup} disabled={!canEdit}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmEditSetting);