import React from "react";
import Pagination from "react-js-pagination";
import { LIST_PAGE_SIZE } from "../models/constants";

interface IPropsPagination {
    updatePageNo: (item: number) => void;
    updatePageSize: (item: string) => void;
    totalItem: number;
    pageSize: number;
    pageNo: number;
}

const PaginationPage = (props: IPropsPagination) => {
    const { totalItem, pageSize, pageNo, updatePageNo, updatePageSize } = props;

    const changePage = (event: any) => {
        if (updatePageNo) {
            updatePageNo(event);
        }
    }

    const changePageSize = (event: any) => {
        if (updatePageSize) {
            updatePageSize(event.target.value);
        }
    }

    const calFromPage = () => {
        return (pageNo - 1) * pageSize + 1
    }

    const calToPage = () => {
        if (pageNo * pageSize > totalItem) {
            return totalItem;
        }
        return pageNo * pageSize
    }

    return (
        <div
            className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
            <div className="d-flex align-items-center">
                <select className="form-select" style={{ width: '80px' }} onChange={changePageSize}>
                    {LIST_PAGE_SIZE.map((size, ind) => (<option value={size} key={ind}>{size}</option>))}
                </select>
                {calToPage() > 0 && <div className="ml-1"> {calFromPage()} to {calToPage()} of {totalItem}</div>}
                {calToPage() === 0 && <div className="ml-1"> 0 to {calToPage()} of {totalItem}</div>}
            </div>
            <div className="pt-2">
                <Pagination
                    activePage={pageNo}
                    totalItemsCount={totalItem}
                    itemsCountPerPage={pageSize}
                    pageRangeDisplayed={5}
                    prevPageText={'Prev'}
                    nextPageText={'Next'}
                    onChange={changePage}
                    innerClass={'pagination pagination-sm'}
                    itemClass={'paginate_button page-item'}
                    linkClass={'page-link'}
                />
            </div>

        </div>

    )
}
export default React.memo(PaginationPage);