import { memo } from "react";

const PayoutSymbol = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Payout and Symbol Configuration</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Payout Configuration</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/payout-configuration.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can directly set up value for payout variables to calculate Payout Rate by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Payout Configuration] Menu in the Side Menu to open Payout settings screen.</li>
                        <li>2. Input value for [Smoothness].</li>
                        <li>3. Input value which is &gt; or = 0 for [Mathematics].</li>
                        <li>4. Input value which is &gt; or = 0 for [Logistic Basis].</li>
                        <li>5. Input value for [Logistic Basis Valuable].</li>
                        <li>6. Click on [Update] button to save all the change.</li>
                    </ul>
                    <div className="text-center">
                        <img
                            src={`/img/manual/payout-confirm-popup.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">Upon that, a confirmation pop up will appear.</div>
                    <ul className="list-instruction ms-5">
                        <li>7. Click on the [Confirm] button to apply all the changes.</li>
                        <li>8. Click on [Cancel] or [X] button to cancel the changes.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>2. Symbol Configuration</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/symbol-configuration.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can directly set up value for symbol variables to calculate Payout Rate by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Symbol Configuration] Menu in the Side Menu to open Symbol settings screen.</li>
                        <li>2. Input value for [Spread Balance] with max number of decimals equivalent to value in [Digits] colume for each symbol.</li>
                        <li>{"3. Input 2 decimals number for [Max Payout] which is {Min_Payout} <= Max Payout <= 2."}</li>
                        <li>{"4. Input 2 decimals number for [Min Payout] which is 1 <= Min Payout <= Max Payout."}</li>
                        <li>5. Input value for [Digits]. Highly recommend input value for digit for each symbol similar to value of digit of that symbol in MT5.</li>
                        <li>6. Click on [Update] button to save all the change.</li>
                        <div className="text-center">
                            <img
                                src={`/img/manual/payout-confirm-popup.svg`}
                                width="100%"
                            />
                        </div>
                        <li>Upon that, a confirmation pop up will appear.</li>
                        <li>7. Click on [Confirm] button to apply all the change.</li>
                        <li>8. Click on [Cancel] or [X] button to cancel the change.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(PayoutSymbol);