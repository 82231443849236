import React, { useEffect } from "react";
import Sidebar from './sidebar';
import Header from './header';

const Layout = ({ children }: any) => {
    return (
        <>
            <Header />
            <Sidebar />
            <div className="mainbody-wrapper">
                <div className="mainbody-container">
                    <div className="mainbody">
                        <section className="loadcontent">
                            {children}
                        </section>
                    </div>
                </div>
            </div>
        </>

    )
}

export default React.memo(Layout);