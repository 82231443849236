import { memo } from "react";

const MaintainanceSettings = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Maintenance</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Instant Mode Setting</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/instant-maintainance-mode1.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can set up instant Maintenance for BullBear Webtrade on [Maintenance Execution (Instant)] for case the system has any issue or trouble which may need to prevent users from logging in the webtrade. This action will set Maintenance screen on BullBear Webtrade and log out all user are logging in the website.</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Maintenance Start Now] to start Maintenance mode immediately.</li>
                        <li>2. Click on [Maintenance End Now] to end Maintenance mode immediately.</li>
                    </ul>
                    <div className="mt-4">Upon clicking on [Maintenance Start Now], a preparation pop up will appear for user to confirm.</div>
                    <div className="text-center">
                        <img
                            src={`/img/manual/instant-maintainance-mode2.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Prepare] button to start preparing for instant maintenance.</li>
                        <li>2. Click on [No] to cancel.</li>
                    </ul>
                    <div className="mt4">Upon clicking on [Prepare] button, a confirmation will appear for user to execute.</div>
                    <div className="text-center">
                        <img
                            src={`/img/manual/instant-maintainance-mode3.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Run] button to execute final step for running instant maintenance.</li>
                        <li>2. Click on [No] to cancel.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>2. Schedule Mode Settings</strong>
                    <p className="fst-italic">1. Create New Schedule</p>
                    <div className="text-center">
                        <img
                            src={`/img/manual/schedule-maintainance-mode.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can also set schedule for Maintenance for BullBear Webtrade by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Input start time.</li>
                        <li>2. Input end time.</li>
                        <li>3. Click on [Apply] button to set schedule for Maintenance.</li>
                    </ul>
                    <p className="fst-italic">2. Edit Scheduled Maintenance</p>
                    <div className="text-center">
                        <img
                            src={`/img/manual/edit-schedule-maintainance.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can edit scheduled maintenance by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Edit] button on the scheduled maintenance record. Upon that a confirmation pop up will appear.</li>
                        <li>2. Edit start time.</li>
                        <li>3. Edit end time.</li>
                        <li>4. Click [Edit] button for apply all the change.</li>
                        <li>5. Click [Cancel] or [X] button to cancel the change.</li>
                    </ul>
                    <p className="fst-italic">3. Cancel Scheduled Maintenance</p>
                    <div className="text-center">
                        <img
                            src={`/img/manual/cancel-schedule-maintainance.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can cancel scheduled maintenance by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Delete] button. Upon that a Confirmation pop up will appear.</li>
                        <li>2. Click on [Yes] button to confirm delete the scheduled maintenance.</li>
                        <li>3. Click on [No] or [X] button to cancel deleting the maintenance.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(MaintainanceSettings);