import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResetPasswordResponse, UserInfo } from "../../interface";
import adminApi from "../../api/admin.api";
import * as Notify from "../../components/notify";
import $ from 'jquery';
import { HttpCode } from "../../models/constants";

interface Props {
    user: UserInfo | undefined,
    clickIndex: number,
    showLoading: (show: boolean) => void
}
const ConfirmResetPassword = (props: Props) => {
    const {t} = useTranslation();
    const {user, clickIndex, showLoading} = props;

    const [password, setPassword] = useState("");
    const [showResult, setShowResult] = useState(false);

    const doReset = () => {
        showLoading(true);
        adminApi.resetPassword(Number(user?.userId)).then(res => {
            let data = res.data as ResetPasswordResponse;
            showLoading(false);
            if (data.meta.code === HttpCode.OK) {
                Notify.success(t('resetPasswordSuccess'));
                setShowResult(true);
                setPassword(data.data.password);
            } else {
                $(".btn-close").click();
                Notify.error(t('resetPasswordFail'));
            }
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('resetPasswordFail'));
        })
    }

    useEffect(() => {
        setShowResult(false);
    }, [clickIndex])

    return (
        <div className="modal fade" id="confirmResetPassword" tabIndex={-1} aria-labelledby="confirmResetPassword" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('resetPasswordTitle')}: <b>{user?.userName}</b></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body py-3">
                        {!showResult && <h6 className="mx-4">{t('resetPasswordMessage')}</h6>}
                        {showResult && <div className="row m-3">
                            <label className="col-sm-4 col-form-label">{t('password')}</label>
                            <label className="col-sm-8 col-form-label"><b>{password}</b></label>    
                        </div>}
                    </div>
                    <div className="modal-footer modal-footer-custom pb-0 mb-3">
                        <button type="button" className="btn btn-default btn-cancel" data-bs-dismiss="modal" aria-label="Close">{t('cancel')}</button>
                        {!showResult && <button type="button" className="btn btn-darkblue mx-2" onClick={doReset}>{t('reset')}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ConfirmResetPassword)