import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import adminApi from "../../api/admin.api";
import * as Notify from "../../components/notify";
import $ from 'jquery';
import { HttpCode, LocalStorageKey } from "../../models/constants";
import { isEmpty } from "../../utils";
import { Md5 } from "ts-md5";
import { BaseResponse, ChangePasswordRequest } from "../../interface/index";

interface Props {
    clickIndex: number,
    showLoading: (show: boolean) => void
}
const ChangePassword = (props: Props) => {
    const {t} = useTranslation();
    const {clickIndex,showLoading} = props;
    const [check, setCheck] = useState(false);
    const [loginId, setLoginId] = useState(localStorage.getItem(LocalStorageKey.LOGIN_ID)  ?? "");

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [currentPassError, setCurrentPasswordError] = useState("");
    const [newPassError, setNewPasswordError] = useState("");
    const [confirmNewPassError, setConfirmNewPasswordError] = useState("");
    const encryptPass = localStorage.getItem(LocalStorageKey.PASSWORD) ?? "";

    useEffect(() => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
    }, [clickIndex]);

    useEffect(() => {
        if(isEmpty(currentPassword)) setCurrentPasswordError(t('fieldRequired') as string);
        else {
            let xPass = Md5.hashStr(currentPassword);
            if (xPass !== encryptPass) setCurrentPasswordError(t('currentPassInvalid') as string);
            else setCurrentPasswordError("");
        }
    }, [currentPassword])

    useEffect(() => {
        if(isEmpty(newPassword)) setNewPasswordError(t('fieldRequired') as string);
        else {
            if(newPassword.length < 8) setNewPasswordError(t('passwordNote') as string);
            else setNewPasswordError("");
        }
    }, [newPassword])

    useEffect(() => {
        if(isEmpty(confirmNewPassword)) setConfirmNewPasswordError(t('fieldRequired') as string);
        else {
            if (confirmNewPassword !== newPassword) setConfirmNewPasswordError(t('confirmPassInvalid') as string);
            else setConfirmNewPasswordError("");
        }
    }, [confirmNewPassword])

    useEffect(() => {
        if (isEmpty(currentPassError) && isEmpty(newPassError) && isEmpty(confirmNewPassError))
            setCheck(true);
        else setCheck(false);
    }, [currentPassError,newPassError,confirmNewPassError])

    const doUpdatePassword = () => {
        let params: ChangePasswordRequest = {
            passwordOld: currentPassword,
            passwordNew: newPassword
        }

        showLoading(true);
        adminApi.changePassword(loginId,params).then(res => {
            showLoading(false);
            let data = res.data as BaseResponse;
            if (data.meta.code === HttpCode.OK) {
                $(".btn-close").click();
                Notify.success(t('changePasswordSuccess'));
            } 
            else Notify.error(t('changePasswordFail'));
        }, err => {
            showLoading(false);
            Notify.error(t('changePasswordFail'));
        })
    }

    return (
        <div className="modal fade" id="changePassword" tabIndex={-1} aria-labelledby="changePassword" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('changePass')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <p className="mb-1">{t('changePassNote')}</p>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('currentPass')} <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                                {!isEmpty(currentPassError) && <label className="text-danger">{currentPassError}</label>}
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('newPass')} <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                {!isEmpty(newPassError) && <label className="text-danger">{newPassError}</label>}
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('confirmNewPass')} <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                                {!isEmpty(confirmNewPassError) && <label className="text-danger">{confirmNewPassError}</label>}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-bs-dismiss="modal">{t('cancel')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" disabled={!check} onClick={doUpdatePassword}>{t('updateBtn')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ChangePassword);