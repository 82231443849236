import { memo, useEffect, useState } from "react"
import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import PaginationPage from "../components/pagination";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, FunctionName, HttpCode, RightValue } from "../models/constants";
import ConfirmAddOperator from "../components/modal/confirm-add-operator";
import ConfirmEditOperator from "../components/modal/confirm-edit-operator";
import ConfirmResetPassword from "../components/modal/confirm-reset-password";
import ConfirmEnableOperator from "../components/modal/confirm-enable-operator";
import ConfirmDisableOperator from "../components/modal/confirm-disable-operator";
import NoRecord from "../components/no-record";
import Loading from "../components/loading";
import adminApi from "../api/admin.api";
import { GroupInfo, GroupResponse, RightInfo, UserInfo, UserListRequest, UserListResponse } from "../interface";
import UserAdminRow from "../components/row/user-admin-row";
import { getRight } from "../utils";

const OperatorManagement = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [total, setTotal] = useState<number>(0);
    const [searchIndex, setSearchIndex] = useState(0);
    const [clickIndex, setClickIndex] = useState(0);

    const [userName, setUserName] = useState("");
    const [groupId, setGroupId] = useState("0");
    const [listGroup, setListGroup] = useState<GroupInfo[]>([]);
    const [listUser, setListUser] = useState<UserInfo[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserInfo>();

    const [right, setRight] = useState<RightInfo>();

    useEffect(() => {
        let mRight = getRight(FunctionName.OPERATOR_LIST);
        if (mRight) setRight(mRight);
    }, [])

    useEffect(() => {
        getGroup();
    }, [])

    useEffect(() => {
        getUserList();
    }, [pageNo, pageSize, searchIndex])

    const getUserList = () => {
        let params: UserListRequest = {
            userName: userName ? userName: null,
            groupId: groupId !== "0" ? groupId: null,
            pageNo: pageNo,
            pageSize: pageSize
        }
        setIsLoading(true);

        adminApi.getListUser(params).then(res => {
            setIsLoading(false);
            let data = res.data as UserListResponse;
            if (data.meta.code === HttpCode.OK) {
                setTotal(data.data.totalRecords);
                setListUser(data.data.userList ?? []);
            } else {
                setTotal(0);
                setListUser([]);
            }
        }, error => {
            setTotal(0);
            setListUser([]);
            setIsLoading(false);
        })
    }

    const getGroup = () => {
        adminApi.getListActiveGroup().then(res => {
            let data = res.data as GroupResponse;
            if (data.meta.code === HttpCode.OK) {
                setListGroup(data.data.groupList.sort((a,b) => a.groupId - b.groupId));
            }else setListGroup([]);
        })
    }

    const showLoading = (show: boolean) => {
        setIsLoading(show);
    }

    const changePageSize = (value: string) => {
        setPageSize(Number(value));
        setPageNo(DEFAULT_PAGE_NO);
    }

    const onSearch = () => {
        setPageNo(DEFAULT_PAGE_NO);
        setSearchIndex(searchIndex + 1);
    }

    const handleEditOperator = (user: UserInfo) => {
        setClickIndex(clickIndex + 1)
        setSelectedUser(user);
    }

    const handleResetPassword = (user: UserInfo) => {
        setClickIndex(clickIndex + 1);
        setSelectedUser(user);
    }

    const handleEnableOperator = (user: UserInfo) => {
        setSelectedUser(user);
    }

    const handleDisableOperator = (user: UserInfo) => {
        setClickIndex(clickIndex + 1);
        setSelectedUser(user);
    }

    const handleKeyPress = (event : any) => {
        if(event.key === 'Enter') onSearch();
    }

    const renderSearch = () => {
        return (
            <div className="row my-4" onKeyDown={handleKeyPress}>
                    <label htmlFor="" className="col-sm-1 col-form-label fw-bold w-fit_content">{t('userName')}</label>
                    <div className="col-sm-2">
                        <input type="input" className="form-control" placeholder={t('searchByUserName')!}  value={userName} onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <label htmlFor="" className="col-sm-1 col-form-label fw-bold w-fit_content">{t('group')}</label>
                    <div className="col-sm-2">
                        <select className="form-select" aria-label="" value={groupId} onChange={(e) => setGroupId(e.target.value)}>
                            <option value="0">{t('all')}</option>
                            {listGroup && listGroup.map((group, idx) => {
                                return(
                                    <option key={idx} value={group.groupId}>{group.groupName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-sm-2 pe-0">
                        <button type="button" className="btn btn-darkblue" onClick={onSearch}>
                            <i className="bi bi-search" /> {t('searchBtn')}
                        </button>
                    </div>
                    <div className="col-sm-4 text-end">
                        {right?.rights === RightValue.FULL &&<button type="button" className="btn btn-w-auto btn-success" onClick={() => setClickIndex(clickIndex + 1)} data-bs-toggle="modal" data-bs-target="#confirmAddOperator">
                            {t('addOperator')}<i className="bi bi-person-plus ps-2" />
                        </button>}
                    </div>
                </div>
        )
    }

    const renderTable = () => {
        return (
            <div className="table-responsive mt-4">
                <table className="blockmodule-table table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-center">
                                {t('userName')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('fullName')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('email')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('group')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('registerTime')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('operatorStatus')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('resetPassword')}
                            </th>
                            <th scope="col" className="align-middle text-center" />
                        </tr>
                    </thead>
                    <tbody>
                        {listUser.map((user,idx) => {
                            return (
                                <UserAdminRow key={user.userId} user={user} right={right}
                                    handleDisableOperator={handleDisableOperator}
                                    handleEnableOperator={handleEnableOperator}
                                    handleEditOperator={handleEditOperator}
                                    handleResetPassword={handleResetPassword}/>
                            )
                        })}

                        {total === 0 && <NoRecord col={8} />}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Layout>
            <section id="operatorManagement">
                <div className="blockmodule blockstock blockmoule-fullpage">
                    <div className="blockmodule-wrap">
                        <div className="blockmodule-wrap--shadown">
                            <div className="blockmodule-title">
                                <h2>{t('operatorList')}</h2>
                            </div>
                            <div className="blockmodule-mainbody">
                                {renderSearch()}
                                {renderTable()}
                                {total > 0 &&<div className="blockmodule-footer my-1">
                                    <PaginationPage
                                        pageNo={pageNo}
                                        pageSize={pageSize}
                                        totalItem={total}
                                        updatePageSize={changePageSize}
                                        updatePageNo={setPageNo}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                    <Loading loading={isLoading} />
                </div>
                <ConfirmAddOperator listGroup={listGroup} clickIndex={clickIndex} showLoading={showLoading} onReload={onSearch}/>
                <ConfirmEditOperator user={selectedUser} clickIndex={clickIndex} listGroup={listGroup} showLoading={showLoading} onReload={onSearch}/>
                <ConfirmResetPassword user={selectedUser} clickIndex={clickIndex} showLoading={showLoading}/>
                <ConfirmEnableOperator user={selectedUser} showLoading={showLoading} onReload={onSearch}/>
                <ConfirmDisableOperator user={selectedUser} clickIndex={clickIndex} showLoading={showLoading} onReload={onSearch}/>
            </section>
        </Layout>
    )
}
export default memo(OperatorManagement);