import { memo } from "react";

const OperatorManagement = () => {
    return (
        <div>
            <div className="manual-intruction-title intruction">Operator List</div>
            <div className="intruction-content px-4">
                <div className="mt-4">
                    <strong>1. Operator List</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/operator-management.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can see list of operators in Admin including name, email address, group of the user, and account status. If account is Enable which means the account is active, can login the Admin Page. If the account is Disable which means the account is inactive, can not login the Admin Page.</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Operator List] Menu in the Side Menu.</li>
                        <li>2. Input user name to search for specific user name.</li>
                        <li>3. Choose Group in droplist to search for a specific group of user.</li>
                        <li>4. Click on [Search] button to apply searching conditions and get the result in the screen.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>2. Add Operator</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/create-account.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can create operator user who can access to Admin Page by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Add operator] button in the top right corner. Upon that, a create account pop up will appear.</li>
                        <li>2. Fill in the username which is required.</li>
                        <li>3. Fill in the Full Name of the user which is required.</li>
                        <li>4. Fill in the email address of the user which is required.</li>
                        <li>5. Choose Group that user can belong to.</li>
                        <li>6. Click [Submit] to create user.</li>
                        <li>7. Click [Cancel] or [X] button to cancel creating user.</li>
                    </ul>
                    <div className="mt-4">Upon that information pop up for newly created user will be shown on the screen which displays username, email address, full name and password.</div>
                    <div className="text-center">
                        <img
                            src={`/img/manual/create-account-success.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">Click [Cancel] or [X] button to close the pop up.</div>
                </div>

                <div className="mt-4">
                    <strong>3. Edit Operator</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/edit-account.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can edit information of a operator including Full Name and Group of the user.</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on [Edit] button in a record of an operator. Upon that, an edit confirmation pop up will appear.</li>
                        <li>2. Input new Full Name.</li>
                        <li>3. Choose different Group that operator can belong to.</li>
                        <li>4. Click [Submit] to apply the change.</li>
                        <li>5. Click [Cancel] or [X] to cancel the change.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <strong>4. Enable/Disable an operator</strong>
                    <div className="text-center">
                        <img
                            src={`/img/manual/enable-disable-account.svg`}
                            width="100%"
                        />
                    </div>
                    <div className="mt-4">User can disable/enable an operator by:</div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on the [Enable/Disable] button. Upon that, a confirmation pop up will appear.</li>
                        <li>2. Fill in reason for enable/disable that account.</li>
                        <li>3. Click on [Disable]/[Enable] button to apply the change.</li>
                        <li>4. Click on [Cancel] or [X] button to cancel the change.</li>
                    </ul>
                    <div className="mt-4">An account which is disable, will not be able to login to the admin screen anymore until it is enable again.</div>
                </div>

                <div className="mt-4">
                    <strong>5. Reset Password</strong>
                    <div className="mt-2">If an operator forgets his/her password, he/she will need to contact the Administrator to reset their password. The Administrator can reset user’s password by:</div>
                    <div className="text-center">
                        <img
                            src={`/img/manual/reset-password.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5">
                        <li>1. Click on the [Reset Password] button on each user record. Upon that a confirmation pop up will appear.</li>
                        <li>2. Click on the [Reset] button to confirm reset password to get new password. A pop up which displays new password will be shown on the screen.</li>
                    </ul>
                    <div className="text-center">
                        <img
                            src={`/img/manual/reset-password-success.svg`}
                            width="100%"
                        />
                    </div>
                    <ul className="list-instruction ms-5">
                        <li>3. Click on [Cancel] or [X] button cancel the request.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default memo(OperatorManagement);