import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclusiveFunctions, FunctionRights, HttpCode, MAX_LENGTH_GROUP_NAME } from "../../models/constants";
import adminApi from "../../api/admin.api";
import { CreateGroupRequest, GroupFunction, GroupFunctionResponse, Permission } from "../../interface";
import * as Notify from "../../components/notify";
import $ from 'jquery';

interface Props {
    showLoading: (value: boolean) => void;
    onReload: () => void;
}

const ConfirmCreateGroup = (props: Props) => {
    const { t } = useTranslation();
    const { showLoading, onReload } = props;

    const [groupName, setGroupName] = useState('');
    const [errorMsg, setErrorMsg] = useState(t('groupNameBlankError'));
    const [canEdit, setCanEdit] = useState(false);
    const [groupFunctions, setGroupFunctions] = useState<GroupFunction[]>();
    const [groupFunctionsUpdate, setGroupFunctionsUpdate] = useState<GroupFunction[]>();

    useEffect(() => {
        getGroupFunctions();
    }, []);

    useEffect(() => {
        let hasGroupRights = groupFunctionsUpdate?.some((groupFunction) => groupFunction?.rights ? groupFunction?.rights !== FunctionRights.NONE : false);
        
        if (errorMsg || !hasGroupRights) {
            setCanEdit(false);
        } else {
            setCanEdit(true);
        }
    }, [groupFunctionsUpdate, groupName]);

    const getGroupFunctions = () => {
        adminApi.getGroupFunctions().then(res => {
            let data = res?.data as GroupFunctionResponse;
            if (data.meta.code === HttpCode.OK) {
                //This constructor is to make sure {groupFunctions} and {groupFunctionsUpdate} don't point to the same reference
                let dataFilteredFunctions = data?.data?.filter((groupFunction) => !ExclusiveFunctions.includes(groupFunction?.functionName));
                let dataFunctions = JSON.parse(JSON.stringify(dataFilteredFunctions));
                let dataFunctionsUpdate = JSON.parse(JSON.stringify(dataFilteredFunctions));
                setGroupFunctions(dataFunctions);
                setGroupFunctionsUpdate(dataFunctionsUpdate);
            }
        })
    }

    const handleCreateGroup = () => {
        let permissionList: Permission[] = groupFunctionsUpdate?.map((groupFunction) => {
            return {functionId: groupFunction?.functionId, rights: groupFunction?.rights || FunctionRights.NONE} as Permission;
        }) || [];
        let params : CreateGroupRequest = {
            groupName: groupName,
            permission: permissionList,
        }

        showLoading(true);

        adminApi.createGroup(params).then(res => {
            showLoading(false);
            if (res?.data?.meta?.code === HttpCode.OK) {
                $(".btn-close").click();
                onReload();
                Notify.success(t('createGroupSuccess'));
            } else {
                Notify.error(t('createGroupFail'));
            }
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('createGroupFail'));
        })

        clearModalData();
    }

    const handleUpdateFunctions = (type: number, functionId: number, rights: number | undefined) => {
        let newGroupFunctionUpdate = [...groupFunctionsUpdate || []];
        let selectedFunction = newGroupFunctionUpdate!.find((groupFunction) => groupFunction?.functionId === functionId);

        if ((rights && (rights >= type) && (rights & type))) {
            if (type === FunctionRights.READ) {
                Object.assign(selectedFunction!, {...selectedFunction, rights: FunctionRights.NONE});
            } else if (rights === FunctionRights.WRITE) {
                Object.assign(selectedFunction!, {...selectedFunction, rights: FunctionRights.READ});
            } else {
                Object.assign(selectedFunction!, {...selectedFunction, rights: rights ^ type});
            }
            setGroupFunctionsUpdate(newGroupFunctionUpdate);
        } else {
            Object.assign(selectedFunction!, {...selectedFunction, rights: (rights ? rights : FunctionRights.NONE) | +type});
            setGroupFunctionsUpdate(newGroupFunctionUpdate);
        }
    }

    const getCheckState = (type: number, rights: number | undefined) => {
        if (rights && (rights >= type) && (rights & type)) return true;
        return false;
    }

    const handleChangeGroupName = (e: any) => {
        let value = e?.target?.value;

        if (!value) {
            setErrorMsg(t('groupNameBlankError')!);
        } else if (value?.length >= MAX_LENGTH_GROUP_NAME) {
            setErrorMsg(t('groupNameLengthError')!);
        } else setErrorMsg('');
        setGroupName(value);
    }

    const clearModalData = () => {
        setGroupName('');
        setErrorMsg(t('groupNameBlankError'));
        setGroupFunctionsUpdate(groupFunctions);
    }

    const renderTable = () => {
        return (
            <div className="table-responsive table-modal mt-4">
                <table className="blockmodule-table table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col" className="align-middle text-center">
                                {t('functions')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('read')}
                            </th>
                            <th scope="col" className="align-middle text-center">
                                {t('write')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupFunctionsUpdate?.map((groupFunction, index) => {
                            return (
                                <tr key={groupFunction?.functionId} className="text-center">
                                    <td>{groupFunction?.functionName}</td>
                                    <td>
                                        <input type="checkbox" checked={getCheckState(FunctionRights.READ, groupFunction?.rights)} 
                                            onChange={() => handleUpdateFunctions(FunctionRights.READ, groupFunction?.functionId, groupFunction?.rights)}/>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={getCheckState(FunctionRights.WRITE, groupFunction?.rights)}
                                            onChange={() => handleUpdateFunctions(FunctionRights.WRITE, groupFunction?.functionId, groupFunction?.rights)}/>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="modal fade" id="confirmCreateGroup" tabIndex={-1} aria-labelledby="confirmCreateGroup" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('createGroup')}</h5>
                        <button type="button" className="btn-close" onClick={clearModalData} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <div className="row">
                                <label className="col-form-label col-sm-4">{t('groupName')}</label>
                                <div className="col-sm-8">
                                    <input type="input" className="form-control" value={groupName} onChange={(e) => handleChangeGroupName(e)} />
                                    {
                                        errorMsg && 
                                            <div className="col-sm-8 text-danger">{errorMsg}</div>
                                    }
                                </div>
                            </div>
                            {renderTable()}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={clearModalData} data-bs-dismiss="modal">{t('cancel')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" onClick={handleCreateGroup} disabled={!canEdit}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmCreateGroup);