import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalStorageKey, RouteUrl } from "../models/constants";
import useDetectMobile from "../hooks/useDetectMobile";
import adminApi from "../api/admin.api";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./modal/change-password";
import Loading from "./loading";

const Header = () => {
    const { t } = useTranslation();
    const [loginId, setLoginId] = useState(localStorage.getItem(LocalStorageKey.LOGIN_ID));
    const isMobile = useDetectMobile();
    const navigate = useNavigate();

    const [clickIndex, setClickIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem(LocalStorageKey.TOKEN);
        if (!accessToken) navigate(RouteUrl.LOGIN);
    }, [])

    const logout = () => {
        adminApi.logout();
        localStorage.clear();
    }

    const showLoading = (show: boolean) => {
        setIsLoading(show);
    }

    return (
        <>
            <header className="navbar-wrapper">
                <nav className="navbar-header">
                    <div className={`navbar-logo pe-0 ${process.env.REACT_APP_BACKGROUND}`}>
                        {!isMobile && <a href={RouteUrl.GENERAL_SETTING} className="navbar-logo__text">
                            {process.env.REACT_APP_TITLE}
                        </a>}
                    </div>
                    <div className="navbar-container">
                        <ul className="nav-left visually-hidden">
                            <li className="nav-tool nav-search">
                                <i className="bi bi-search"/>
                            </li>
                        </ul>
                        <ul className="nav-right">
                            <li className="user-profile">
                                <div className="dropdown-primary" role="button" id="dropdownprofile" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="user-profile__avatar">
                                        <i className="bi bi-person-circle"/>
                                    </div>
                                    <div className="user-profile__username">
                                        {loginId}
                                    </div>
                                    <i className="bi bi-chevron-down"/>
                                </div>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-primary__menu dropdown-primary__menu--end" aria-labelledby="dropdownprofile">
                                    <li>
                                        <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#changePassword" onClick={e => setClickIndex(clickIndex + 1)}>
                                            <i className="bi bi-person"></i> {t('changePass')}
                                        </a>
                                    </li>
                                    <li onClick={logout}>
                                        <a className="dropdown-item" href="/login">
                                            <i className="bi bi-box-arrow-right"/> {t('logout')}
                                        </a>
                                    </li>
                                    
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <ChangePassword clickIndex={clickIndex} showLoading={showLoading}/>
            <Loading loading={isLoading}/>
        </>
        
    )
}
export default memo(Header);