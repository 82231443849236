import { ApiPath } from "../models/constants";
import API from "./config.api";
import APIJson from "./config-json.api";
import { LoginHistoryRequest, MaintenanceRequest, MaintenanceExeInfo,MaintenanceHistoryRequest, UserListRequest, CreateUserRequest, EditGroupRequest, CreateGroupRequest, EditUserRequest, ChangePasswordRequest, AuditHistoryRequest } from "../interface";

export default {
    login(params: any) {
        return APIJson.post(ApiPath.LOGIN, params);
    },
    logout(){
        return API.post(ApiPath.LOGOUT, null);
    },
    getGeneralConfig(){
        return API.get(ApiPath.GET_GENERAL_CONFIG);
    },
    getSymbolConfig(){
        return API.get(ApiPath.GET_SYMBOL_CONFIG);
    },
    updateGeneralConfig(params: any){
        return API.post(ApiPath.UPDATE_GENERAL_CONFIG, params);
    },
    updateSymbolConfig(params: any){
        return API.post(ApiPath.UPDATE_SYMBOL_CONFIG, params);
    },
    getOnlineUsers(params: LoginHistoryRequest){
        return APIJson.get(ApiPath.GET_ONLINE_USER, {params: params});
    },
    getLoginHistory(params: LoginHistoryRequest){
        return APIJson.get(ApiPath.GET_LOGIN_HISTORY, {params: params});
    },
    exportLoginHistory(params: any){
        return APIJson.get(ApiPath.EXPORT_LOGIN_HISTORY, {params: params});
    },
    forceLogout(id: string) {
        return(APIJson.post(ApiPath.FORCE_LOGOUT + "?id=" + id, null))
    },
    createMaintenance(params: MaintenanceRequest){
        return(APIJson.post(ApiPath.CREATE_MAINTENANCE, params));
    },
    updateMaintenance(id: string, action: string, params: MaintenanceRequest | null){
        return(APIJson.post(ApiPath.EDIT_MAINTENANCE + "?id=" + id + "&action=" + action, params));
    },
    executeMaintenance(params: MaintenanceExeInfo){
        return(APIJson.post(ApiPath.EXECUTE_MAINTENANCE, params));
    },
    getMaintenanceHistory(params: MaintenanceHistoryRequest){
        return APIJson.get(ApiPath.GET_MAINTENANCE_HISTORY, {params: params});
    },
    getListUser(params: UserListRequest){
        return APIJson.get(ApiPath.GET_USER_LIST, {params: params});
    },
    getListActiveGroup(){
        return APIJson.get(ApiPath.GET_GROUP_LIST);
    },
    createUser(params: CreateUserRequest){
        return APIJson.post(ApiPath.CREATE_USER, params);
    },
    editUser(id: number,params: EditUserRequest){
        return APIJson.post(ApiPath.USER_API + "edit/" + id, params);
    },
    resetPassword(id: number) {
        return APIJson.post(ApiPath.USER_API + "reset-password/" + id, null);
    },
    changePassword(userName: string,params: ChangePasswordRequest){
        return(APIJson.post(ApiPath.CHANGE_PASSWORD + "?userName=" + userName, params));
    },
    getListRight(groupId: string) {
        return APIJson.get(ApiPath.GET_RIGHT_LIST + "?groupId=" + groupId);
    },
    getGroupFunctions(){
        return APIJson.get(ApiPath.GET_GROUP_FUNCTIONS);
    },
    createGroup(params: CreateGroupRequest) {
        return APIJson.post(ApiPath.CREATE_GROUP, params);
    },
    getGroupRights(id: any) {
        return APIJson.get(ApiPath.GET_GROUP_RIGHTS + "?groupId=" + id);
    },
    editGroup(id: number, params: EditGroupRequest) {
        return APIJson.post(ApiPath.GROUP_API + "edit/" + id, params);
    },
    getAuditHistory(params: AuditHistoryRequest){
        return APIJson.get(ApiPath.GET_AUDIT_HISTORY, {params: params});
    },
    exportAuditHistory(params: any){
        return APIJson.get(ApiPath.EXPORT_AUDIT_HISTORY, {params: params});
    },
    prepareMaintenance(){
        return APIJson.post(ApiPath.PREPARE_MAINTENANCE, null);
    },
}