import axios from 'axios';
import { HttpCode, LocalStorageKey, RouteUrl } from "../models/constants";
import { AxiosRequestConfig } from "axios";

const axiosJson: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    timeout: 30000,
  }

const client = axios.create(axiosJson);

const ClientService = {
  get(url: string, config?: any) {
    return client.get(url, {...this.configHeader(), ...config});
  },

  getBinary(url: string, config?: any) {
    return client.get(url, {...this.configHeader(), ...config, responseType: 'blob'});
  },

  post(url: string, params: any, config?: any) {
    return client.post(url, params, {...this.configHeader(), ...config});
  },

  put(url: string, params: any, config?: any) {
    return client.put(url, params, {...this.configHeader(), ...config});
  },

  delete(url: string, config?: any) {
    return client.delete(url, {...this.configHeader(), ...config});
  },

  configHeader() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${localStorage.getItem(LocalStorageKey.TOKEN)}`
      }
    }
  }
};

client.interceptors.response.use(res => {
  return res;
},error => {
  if(error?.response?.status === HttpCode.UNAUTHORIZED) {
    localStorage.clear();
    window.location.pathname = RouteUrl.LOGIN;
  } else {
    throw error;
  }
})

export default ClientService;
