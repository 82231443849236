import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupInfo, UserListRequest, UserListResponse } from "../../interface";
import adminApi from "../../api/admin.api";
import * as Notify from "../../components/notify";
import $ from 'jquery';
import { OperatorStatus, DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, HttpCode } from "../../models/constants";

interface Props {
    showLoading: (value: boolean) => void;
    group: GroupInfo | undefined;
    onReload: () => void;
}

const ConfirmDeleteGroup = (props: Props) => {
    const { t } = useTranslation();
    const { showLoading, group, onReload } = props;
    
    const [validFlag, setValidFlag] = useState(false);

    useEffect(() => {
        if (group?.groupId)
        getUserList();
    }, [group?.groupId]);

    const getUserList = () => {
        let params: UserListRequest = {
            groupId: (group && group?.groupId !== 0) ? group?.groupId?.toString() : null,
            pageNo: DEFAULT_PAGE_NO,
            pageSize: DEFAULT_PAGE_SIZE,
        }

        showLoading(true);

        adminApi.getListUser(params).then(res => {
            showLoading(false);
            let data = res.data as UserListResponse;
            if (data.meta.code === HttpCode.OK) {
                let haveActiveUsers = data?.data?.userList?.some((user) => user?.status === OperatorStatus.ACTIVE);
                if (haveActiveUsers) setValidFlag(false);
                else setValidFlag(true);
            } else setValidFlag(false);
        }, error => {
            setValidFlag(false);
            showLoading(false);
        })
    }

    const handleDeleteGroup = () => {
        let params = {
            group: {
                name: group?.groupName || '',
                status: "2",
            },
            permission: [],
        }

        showLoading(true);

        adminApi.editGroup(group!.groupId, params).then(res => {
            showLoading(false);
            $(".btn-close").click();
            onReload();
            Notify.success(t('deleteGroupSuccess'));
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('deleteGroupFail'));
        })
    }

    return (
        <div className="modal fade" id="confirmDeleteGroup" tabIndex={-1} aria-labelledby="confirmDeleteGroup" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('deleteGroup')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <div className="text-center mb-2 fs-6">{t('deleteGroupMessage')}</div>
                            {
                                !validFlag && <div className="text-center text-danger">{t('errorDeleteGroupMsg')}</div>
                            }
                        </form>
                    </div>
                    <div className="modal-footer justify-content-around">
                        <button type="button" className="btn btn-default" data-bs-dismiss="modal">{t('no')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" onClick={handleDeleteGroup} disabled={!validFlag}>{t('yes')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmDeleteGroup);