import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseResponse, EditUserRequest, UserInfo } from "../../interface";
import adminApi from "../../api/admin.api";
import * as Notify from "../../components/notify";
import { HttpCode } from "../../models/constants";
import $ from 'jquery';

interface Props {
    user: UserInfo | undefined,
    clickIndex: number,
    showLoading: (show: boolean) => void,
    onReload: () => void
}
const ConfirmDisableOperator = (props: Props) => {
    const {t} = useTranslation();
    const {user, clickIndex, showLoading, onReload} = props;

    const [note,setNote] = useState("");

    useEffect(() => {
        setNote("");
    }, [clickIndex])

    const doUpdate = () => {
        if (user) {
            let params: EditUserRequest = {
                fullName: user?.fullName,
                group: user?.groupId.toString(),
                status: false,
                reason: note ? note : ""
            }

            showLoading(true);
            adminApi.editUser(user?.userId, params).then(res => {
                $(".btn-close").click();
                let data = res.data as BaseResponse;
                showLoading(false);
                if (data.meta.code === HttpCode.OK) {
                    Notify.success(t('disableUserSuccess'));
                    onReload();
                } else Notify.error(t('disableUserFail'));
            }, error => {
                $(".btn-close").click();
                showLoading(false);
                Notify.error(t('disableUserFail'));
            })
        }
    }
    
    return (
        <div className="modal fade" id="confirmDisableOperator" tabIndex={-1} aria-labelledby="confirmDisableOperator" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('disableOperatorTitle')}: <b>{user?.userName}</b></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body py-3 px-4">
                        <h6>{t('disableOperatorMessage')}</h6>
                        <h6 className="mt-3">{t('note')} 
                            {/* <span className="text-danger">*</span> */}
                        </h6>
                        <textarea className="form-control" value={note} onChange={e => setNote(e.target.value)}></textarea>
                    </div>
                    <div className="modal-footer modal-footer-custom pb-0 mb-3">
                        <button type="button" className="btn btn-default btn-cancel" data-bs-dismiss="modal" aria-label="Close">{t('cancel')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" onClick={doUpdate}>{t('disable')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ConfirmDisableOperator)