import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { BaseResponse, EditUserRequest, GroupInfo, UserInfo } from "../../interface";
import adminApi from "../../api/admin.api";
import { HttpCode } from "../../models/constants";
import * as Notify from "../../components/notify";
interface Props {
    user: UserInfo | undefined,
    clickIndex: number,
    listGroup: GroupInfo[],
    showLoading: (show: boolean) => void,
    onReload: () => void
}
const ConfirmEditOperator = (props: Props) => {
    const { t } = useTranslation();
    const {user, clickIndex, listGroup, showLoading, onReload} = props;

    const [fullName, setFullName] = useState("");
    const [groupId, setGroupId] = useState("1");

    useEffect(() => {
        if (user) {
            setFullName(user.fullName);
            setGroupId(user.groupId.toString());
        }
    }, [user,clickIndex])

    const editUser = () => {
        let params : EditUserRequest = {
            fullName: fullName,
            status: true,
            group: groupId,
            reason: ""
        }

        showLoading(true);

        adminApi.editUser(Number(user?.userId), params).then(res => {
            let data = res.data as BaseResponse;
            showLoading(false);
            $(".btn-close").click();

            if (data.meta.code === HttpCode.OK) {
                onReload();
                Notify.success(t('editUserSuccess'));
            }else Notify.error(t('editUserFail'));
        }, err => {
            $(".btn-close").click();
            showLoading(false);
            Notify.error(t('editUserFail'));
        })
    }

    return (
        <div className="modal fade" id="confirmEditOperator" tabIndex={-1} aria-labelledby="confirmEditOperator" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('editOperatorInformation')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="m-3">
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('fullName')} <span className="text-danger">*</span></label>
                                <input type="input" className="form-control" value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                                {!fullName && <label className="text-danger">{t('fieldRequired')}</label>}
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-4 col-form-label">{t('group')} <span className="text-danger">*</span></label>
                                <select className="form-select" value={groupId} onChange={(e) => setGroupId(e.target.value)}>
                                    {listGroup && listGroup.map((group, idx) => {
                                        return(
                                            <option key={idx} value={group.groupId}>{group.groupName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="form-group mb-2">
                                <label className="col-sm-2 col-form-label">{t('note')}</label>
                                <label className="col-sm-10"><b>{user?.reason}</b></label>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-bs-dismiss="modal">{t('cancel')}</button>
                        <button type="button" className="btn btn-darkblue mx-2" disabled={!fullName || (fullName === user?.fullName && groupId === user?.groupId.toString())} onClick={editUser}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmEditOperator);