import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { DEFAULT_LANG, LocalStorageKey, ManualRouterUrl, RouteUrl } from './models/constants';
import './App.css';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/main.css';
import './assets/css/style.css';
import { useTranslation } from 'react-i18next';
import {ToastContainer} from "react-toastify";
import OperatorManagement from './pages/operator-management';
import OperatorGroup from './pages/operator-group';
import AuditHistory from './pages/audit-history';
import ManualPayoutSymbol from './components/manual/payout-symbol';
import ManualAuth from './components/manual/auth';
import ManualLoginHistory from './components/manual/login-history';
import ManualOnlineUsers from './components/manual/online-users';
import ManualMaintainance from './components/manual/maintenance';
import ManualOperatorManagement from './components/manual/operator-management';
import ManualGroupSettings from './components/manual/operator-group';
import ManualAuditHistory from './components/manual/audit-history';
import Layout from './components/manual/layout';
import Manual from './pages/manual';

if (typeof window !== "undefined") {
	// require("bootstrap/dist/js/bootstrap.bundle.min");
    require("bootstrap/dist/js/bootstrap.min.js");
}

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const Login = React.lazy(() => import('./pages/login'));
const GeneralSetting = React.lazy(() => import('./pages/general-setting'));
const SymbolSetting = React.lazy(() => import('./pages/symbol-setting'));
const UserOnline = React.lazy(() => import('./pages/user-online'));
const UserHistory = React.lazy(() => import('./pages/user-history'));
const MaintenanceSetting = React.lazy(() => import('./pages/maintenance-setting'));

const RouterDom = () => (
	<React.Suspense fallback={loading}>
		<Routes>
			<Route path="/" element={<Navigate to={RouteUrl.LOGIN} />} />
			<Route path={RouteUrl.LOGIN} element={<Login />} />
			<Route path={RouteUrl.GENERAL_SETTING} element={<GeneralSetting />} />
			<Route path={RouteUrl.SYMBOL_SETTING} element={<SymbolSetting />} />
			<Route path={RouteUrl.USER_ONLINE} element={<UserOnline />} />
			<Route path={RouteUrl.USER_HISTORY} element={<UserHistory />} />
			<Route path={RouteUrl.MAINTENANCE} element={<MaintenanceSetting />} />
            <Route path={RouteUrl.OPERATOR_LIST} element={<OperatorManagement />} />
            <Route path={RouteUrl.GROUP_SETTING} element={<OperatorGroup />} />
            <Route path={RouteUrl.AUDIT_HISTORY} element={<AuditHistory />} />
            <Route path={RouteUrl.MANUAL} element={<Manual/>} />
            <Route path={RouteUrl.MANUAL} element={<Layout />}>
                <Route path={ManualRouterUrl.AUTH} element={<ManualAuth />} />
                <Route path={ManualRouterUrl.PAYOUT_SYMBOL} element={<ManualPayoutSymbol />} />
                <Route path={ManualRouterUrl.LOGIN_HISTORY} element={<ManualLoginHistory />} />
                <Route path={ManualRouterUrl.ONLINE_USER} element={<ManualOnlineUsers />} />
                <Route path={ManualRouterUrl.MAINTAINANCE} element={<ManualMaintainance />} />
                <Route path={ManualRouterUrl.OPERATOR_LIST} element={<ManualOperatorManagement />} />
                <Route path={ManualRouterUrl.GROUP_SETTINGS} element={<ManualGroupSettings />} />
                <Route path={ManualRouterUrl.AUDIT_HISTORY} element={<ManualAuditHistory />} />
            </Route>
		</Routes>
	</React.Suspense>
)

const App = () => {
	const { i18n } = useTranslation();

	let currentLanguage = DEFAULT_LANG;
	if (localStorage.getItem(LocalStorageKey.LANGUAGE))
		currentLanguage = localStorage.getItem(LocalStorageKey.LANGUAGE) as string;

	const [language, setLanguage] = useState(currentLanguage);

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language])

	return (
        <BrowserRouter>
			<RouterDom/>
            <ToastContainer theme="colored"/>
        </BrowserRouter>
    )
}

export default App;