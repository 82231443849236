import { memo } from "react";
import { formatDate } from "../../utils";

interface Props {
    timeRequest: number;
    functionName: string;
    username: string;
    detail: string;
}
const AuditHistoryRow = (props: Props) => {
    const { timeRequest, functionName, username, detail } = props;

    return (
        <tr className="text-start">
            <td>{formatDate(timeRequest)}</td>
            <td>{functionName}</td>
            <td>{username}</td>
            <td colSpan={2}>{detail}</td>
        </tr>
    )
}

export default memo(AuditHistoryRow);
