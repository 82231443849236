import moment from "moment";
import { DateFormat, LocalStorageKey } from "../models/constants";
import { RightInfo } from "../interface";


export const isEmpty = (value: any) => {
    return (undefined === value) || (null === value) || (0 === value.trim().length);
}

export const isNull = (value: any) => {
    return (undefined === value) || (null === value);
}

export const isEmail = (value: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}

export const formatDate = (datetime: number | string) => {
    if (datetime !== null) {
        let date = new Date(datetime);
        let mDateStr = moment(date).format(DateFormat.DateTime);
        return mDateStr;
    } else return null;
}

export const getRight = (funcName: string) => {
    let rightCache = localStorage.getItem(LocalStorageKey.RIGHTS) ?? "";
    if (!isEmpty(rightCache)) {
        let rightList = JSON.parse(rightCache) as RightInfo[];
        let tmpRight = rightList.filter(r => r.functionName === funcName);
        if (tmpRight) return tmpRight[0];
    }
    return null;
}